






































import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { AppLocale } from '@/shared/localization/enumerations/AppLocale';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { inject } from '@corefy/inversify-nuxt';
import { localesAssetsMap } from '@/shared/localization/const/localesAssetsMap';
import { UserStore } from '@/shared/user/store/UserStore';
import { ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';

@Component
export default class LangSwitcher extends Vue {
  private popoverVisible = false;

  @inject(iocTypes.LocalizationService)
  private readonly localizationService!: LocalizationService;

  @inject(iocTypes.UserStore)
  private readonly userStore!: UserStore;

  @inject(iocTypes.ErrorAlertService)
  private readonly errorAlertService!: ErrorAlertService;

  private get pickedLocale() {
    return this.localizationService.locale;
  }

  private get localesList() {
    return this.localizationService.localesList;
  }

  private getLanguages(locale: AppLocale): string {
    return localesAssetsMap[locale].translationKey;
  }

  private async changeLanguage(locale: AppLocale) {
    this.popoverVisible = false;

    if (this.userStore.authorized) {
      await this.userStore.updateUserPreferencesData({ locale });

      if (this.userStore.updateUserPreferencesDataIndicators.error) {
        this.errorAlertService.showError({
          error: this.userStore.updateUserPreferencesDataIndicators.error,
        });
        return;
      }
    }

    await this.$i18n.setLocaleCookie(locale);
    location.reload();
  }
}
