import { inject, injectable } from 'inversify';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { HttpClient, HttpClientFactory } from '@corefy/http/services/HttpClient';
import {
  UserBackupCodesPayload,
  UserInvitationPayload,
  UserOrganizationsPayload,
  UserResponsePayload,
  UserSecretPayload,
} from '@/shared/facade/interfaces/entity/user';
import { UserHttpClient, UserUpdatePayload } from '@/shared/facade/services/UserHttpClient';
import { getBaseFacadeClientConfig } from '@/shared/facade/consts/facadeClientConfig';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

@injectable()
export class DefaultUserHttpClient implements UserHttpClient {
  constructor(
    @inject(iocTypes.HttpClientFactory) createHttpClient: HttpClientFactory,
    @inject(iocTypes.AppDataStore)
    appDataStore: AppDataStore
  ) {
    const { FACADE_URL } = appDataStore.data.env;
    this._httpClient = createHttpClient(getBaseFacadeClientConfig(FACADE_URL));
  }

  getUser() {
    return this._httpClient.get<UserResponsePayload>('/user');
  }

  updateUser (props: UserUpdatePayload) {
    return this._httpClient.patch('/user', props)
  }

  getOrganizations() {
    return this._httpClient.get<UserOrganizationsPayload>('/user/organizations');
  }

  getInvitations() {
    return this._httpClient.get<UserInvitationPayload>('/user/organizations/invites');
  }

  enable2Fa(payload: { code: string }) {
    return this._httpClient.post('/user/2fa/enable', payload);
  }

  switchOrganization(id: string, data: { type: string; id: string }) {
    return this._httpClient.post(`/user/organizations/${id}/switch`, { data });
  }

  getSecret() {
    return this._httpClient.get<UserSecretPayload>('/user/2fa/get-secret');
  }

  getBackupCodes() {
    return this._httpClient.get<UserBackupCodesPayload>('/user/2fa/backup-codes');
  }

  private _httpClient: HttpClient;
}
