import { injectable, inject } from 'inversify';
import { AlertService } from '@corefy/ui/services/alert/AlertService';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { ErrorAlertService, ErrorServiceConfig } from '../services/ErrorAlertService';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';

@injectable()
export class DefaultErrorAlertService implements ErrorAlertService {
  constructor(
    @inject(iocTypes.AlertService) private readonly _alertService: AlertService,
    @inject(iocTypes.LocalizationService) private readonly _localizationService: LocalizationService
  ) {}

  private _t(text: string) {
    return this._localizationService.t(text);
  }

  showError({
    error: e,
    position,
    reference,
    buttons,
    color = 'danger',
    icon = {
      name: 'diamond-exclamation',
      type: 'solid',
    },
  }: ErrorServiceConfig): () => void {
    return this._alertService.showAlert({
      position,
      reference,
      buttons,
      color,
      icon,
      disableClose: false,
      title: this._t(e.name),
      message:
        e.details && Array.isArray(e.details) ? e.details.join('. ') : this._t(e.details || ''),
    });
  }
}
