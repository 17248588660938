const Modal = {
  ConfirmationTokenExpired: 'confirmation_token_expired' as const,
  ResetPassTokenExpired: 'reset_pass_token_expired' as const,
  SignedUnderAnotherAcc: 'signed_under_another_acc' as const,
  OrganizationNotFound: 'organization_not_found',
};

type Modal = typeof Modal[keyof typeof Modal];

export { Modal };
