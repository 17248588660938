









































import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { UserStore } from '@/shared/user/store/UserStore';
import { ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';

@Component
export default class SignedUnderAnotherAcc extends Vue {
  @inject(iocTypes.UserStore)
  private userStore!: UserStore;

  @inject(iocTypes.SystemHttpClient)
  private systemHttpClient!: SystemHttpClient;

  @inject(iocTypes.ErrorAlertService)
  private errorAlert!: ErrorAlertService;

  @Prop({ type: Boolean, default: false })
  private readonly value!: boolean;

  async created() {
    this.token = this.$route.query.token as string;
    await this.getInviteData();
  }

  mounted() {
    this.visible = this.model;
  }

  private logoutEvent = false;
  private visible = false;
  private token = '';
  private email = '';
  private organization = '';
  private inviterEmail = '';

  private get model() {
    return this.value;
  }

  private set model(value: boolean) {
    this.$emit('input', value);
  }

  private get logoutIndicators() {
    return this.userStore.logoutIndicators;
  }

  private async closedHandler() {
    if (this.logoutEvent) {
      this.logoutEvent = false;
      await this.userStore.logout();

      if (this.logoutIndicators.error) {
        this.errorAlert.showError({ error: this.logoutIndicators.error });
      }
    }
    this.model = false;
  }

  private get user() {
    return this.userStore.user;
  }

  private async getInviteData() {
    const resp = await this.systemHttpClient.getMemberInviteByToken(this.token);
    const {
      email,
      organization_name: organizationName,
      inviter_email: inviterEmail,
    } = resp.data.data.attributes;

    this.email = email;
    this.inviterEmail = inviterEmail;
    this.organization = organizationName;
  }

  private async logout() {
    this.logoutEvent = true;
    this.visible = false;
  }
}
