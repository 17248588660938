



































import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import SplittedFooter from '@/modules/entrance/components/footer/SplittedFooter.vue';
import EntranceBlog from '@/modules/entrance/components/blog/EntranceBlog.vue';
import { Modal } from '@/shared/global-modals/enumerations/modals';
import { modalsAssets } from '@/shared/global-modals/assets/modalsAssets';
import type { VueConstructor } from 'vue';
import { omit } from 'lodash';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { BlogStore } from '@/shared/blog/store/BlogStore';
import AppLogo from '@/shared/ui/AppLogo.vue';
import { getFaviconHeaderLinks } from '@/shared/utils/getFaviconHeaderLinks';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

@Component({
  components: {
    SplittedFooter,
    AppLogo,
    EntranceBlog,
  },
})
export default class EntranceSplittedLayout extends Vue {
  @inject(iocTypes.BlogStore)
  blogStore!: BlogStore;

  @inject(iocTypes.AppDataStore)
  appData!: AppDataStore;

  head() {
    return {
      link: getFaviconHeaderLinks(this.appData),
    };
  }

  mounted() {
    this.checkRouteAssets();
  }

  showModal = false;
  modalComponent: VueConstructor | null = null;

  get classes() {
    return {
      'entrance--split': this.showBlog(),
    };
  }

  showBlog() {
    return !this.blogStore.isBlogHidden;
  }

  clearUrlQuery() {
    this.$router.replace({ query: omit(this.$route.query, ['modal']) });
  }

  checkModals(modal: Modal) {
    const modalAsset = modalsAssets.getFor(modal);
    if (modalAsset) {
      this.modalComponent = modalAsset.component;
      this.showModal = true;
      this.clearUrlQuery();
    } else {
      console.error(`Modal ${modal} is not presented in modalAssets`);
    }
  }

  checkRouteAssets() {
    const { modal } = this.$route.query;
    if (modal) {
      this.checkModals(modal as Modal);
    }
  }

  goHome() {
    if (this.$route.name === 'login') return;
    this.$router.push({ name: 'login' });
  }
}
