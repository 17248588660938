






























import { Component, Provide, Vue } from 'nuxt-property-decorator';
import { PortalsStore } from '@/shared/portals/Portals';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { inject } from '@corefy/inversify-nuxt';
import { portal } from '@/shared/portals/enum/portal';
import { Scrollbar } from '@corefy/ui/components/ui-scrollbar';
import { Modal } from '@/shared/global-modals/enumerations/modals';
import { modalsAssets } from '@/shared/global-modals/assets/modalsAssets';
import type { VueConstructor } from 'vue';
import { omit } from 'lodash';
import AppLogo from '@/shared/ui/AppLogo.vue';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { getFaviconHeaderLinks } from '@/shared/utils/getFaviconHeaderLinks';

@Component({
  components: {
    AppLogo,
  },
})
export default class LoggiaMonoLayout extends Vue {
  $refs!: {
    loggia: HTMLElement;
    header: HTMLElement;
    content: HTMLElement;
    scroll: Scrollbar;
    footer: HTMLElement;
  };

  @inject(iocTypes.PortalsStore)
  private readonly portalsStore!: PortalsStore;

  @inject(iocTypes.AppDataStore)
  appData!: AppDataStore;

  @Provide()
  private monoLayout: { footer: 'sticky' | 'initial' } = {
    footer: 'initial',
  };

  head() {
    return {
      link: getFaviconHeaderLinks(this.appData),
    };
  }

  mounted() {
    this.checkRouteAssets();
  }

  private portalTarget = portal;
  private showModal = false;
  private modalComponent: VueConstructor | null = null;

  private get headerPassed() {
    return this.portalsStore.getItem('header');
  }

  private get footerClasses() {
    return [this.monoLayout.footer === 'sticky' ? 'loggia__footer--sticky' : ''];
  }

  private clearUrlQuery() {
    this.$router.replace({ query: omit(this.$route.query, ['modal']) });
  }

  private checkModals(modal: Modal) {
    const modalAsset = modalsAssets.getFor(modal);
    if (modalAsset) {
      this.modalComponent = modalAsset.component;
      this.showModal = true;
      this.clearUrlQuery();
    } else {
      console.error(`Modal ${modal} is not presented in modalAssets`);
    }
  }

  private checkRouteAssets() {
    const { modal } = this.$route.query;
    if (modal) {
      this.checkModals(modal as Modal);
    }
  }
}
