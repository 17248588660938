import { NotificationService, NotificationServiceFactory } from '../NotificationService';
import { Message } from 'element-ui';
import { ErrorInfo } from '@corefy/error/interfaces/ErrorInfo';

export class NotificationServiceImp implements NotificationService {
  constructor() {}

  error(info: ErrorInfo) {
    Message.error({ message: info.name });
  }
}

export const createNotificationServiceFactory: NotificationServiceFactory = () =>
  new NotificationServiceImp();
