














import { Component, Vue } from 'nuxt-property-decorator';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { inject } from '@corefy/inversify-nuxt';

@Component
export default class MonoFooter extends Vue {
  @inject(iocTypes.AppDataStore)
  appData!: AppDataStore;

  get isLinksVisible() {
    return this.appData.data.env.DOC_LINKS_DISABLED !== 'true';
  }
}
