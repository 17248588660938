import { inject, injectable } from 'inversify';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { HttpClient, HttpClientFactory } from '@corefy/http/services/HttpClient';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { getBaseBlogClientConfig } from '@/shared/blog/consts/blogClientConfig';
import { BlogHttpClient } from '@/shared/blog/services/BlogHttpClient';

@injectable()
export class DefaultBlogHttpClient implements BlogHttpClient {
  constructor(
    @inject(iocTypes.HttpClientFactory) createHttpClient: HttpClientFactory,
    @inject(iocTypes.AppDataStore)
    appDataStore: AppDataStore
  ) {
    const { BLOG_URL } = appDataStore.data.env;
    this._httpClient = createHttpClient(getBaseBlogClientConfig(BLOG_URL));
  }

  getPosts(page = 1) {
    return this._httpClient.get(`page:${page}`);
  }

  private _httpClient: HttpClient;
}
