














import { Component, Vue } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { getFaviconHeaderLinks } from '@/shared/utils/getFaviconHeaderLinks';

@Component
export default class Default extends Vue {
  @inject(iocTypes.AppDataStore)
  appData!: AppDataStore;

  head() {
    return {
      link: getFaviconHeaderLinks(this.appData),
    };
  }
}
