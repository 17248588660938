import type { VueConstructor } from 'vue';
import { Modal } from '@/shared/global-modals/enumerations/modals';
import ConfirmationTokenExpired from '@/shared/ui/modals/ConfirmationTokenExpired.vue';
import ResetPassTokenExpired from '@/shared/ui/modals/ResetPassTokenExpired.vue';
import SignedUnderAnotherAcc from '@/shared/ui/modals/SignedUnderAnotherAcc.vue';
import { AssetsProvider } from '@/shared/common/interfaces/AssetsProvider';
import OrganizationNotFound from '@/shared/ui/modals/OrganizationNotFound.vue';

interface ModalAsset {
  component: VueConstructor;
}

const modalMap = {
  [Modal.ConfirmationTokenExpired]: {
    component: ConfirmationTokenExpired,
  },
  [Modal.SignedUnderAnotherAcc]: {
    component: SignedUnderAnotherAcc,
  },
  [Modal.ResetPassTokenExpired]: {
    component: ResetPassTokenExpired,
  },
  [Modal.OrganizationNotFound]: {
    component: OrganizationNotFound,
  },
};

export const modalsAssets: AssetsProvider<Modal, ModalAsset | null> = {
  getFor(value: Modal): ModalAsset | null {
    return modalMap[value] || null;
  },
};
