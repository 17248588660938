import { Middleware } from '@nuxt/types';
import { UserStore } from '@/shared/user/store/UserStore';
import { AuthRedirectService } from '@/shared/auth/services/AuthRedirectService';
import { iocTypes } from '@/shared/ioc/iocTypes';

const middleware: Middleware = async ({ $container, route, redirect }) => {
  const authRedirectService = $container.get<AuthRedirectService>(iocTypes.AuthRedirectService);
  const userStore = $container.get<UserStore>(iocTypes.UserStore);
  if (userStore.authorized) {
    await Promise.all([userStore.getInvitations(), userStore.getOrganizations()]);
    const { redirectTo, children = [] } = authRedirectService.resolveRedirect({
      authorized: userStore.authorized,
      route,
      noOrganizations: !userStore.organizations.length && !userStore.invitations.length,
    });

    if (redirectTo && redirectTo !== route.name && !children.includes(route.name || '')) {
      redirect({ name: redirectTo, query: route.query });
    }
  } else {
    const { redirectTo, children = [] } = authRedirectService.resolveRedirect({
      authorized: userStore.authorized,
      route,
    });

    if (redirectTo && redirectTo !== route.name && !children.includes(route.name || '')) {
      redirect({ name: redirectTo, query: route.query });
    }
  }
};

export default middleware;
