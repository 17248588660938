import { Validator } from '@corefy/validation/interfaces/Validator';
import { createRegexpValidatorFactory } from '@corefy/validation/services/implementations/RegexpValidator';
import { emailRegeXp } from '@/shared/regexps/Email';
import { interfaces } from 'inversify';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { iocTypes } from '@/shared/ioc/iocTypes';

export interface EmailValidator {
  (): Validator;
}

export interface EmailValidatorFactory {
  (options?: { message?: string }): Validator;
}

export const createEmailValidatorFactory = (ctx: interfaces.Context): EmailValidatorFactory => {
  const l = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);
  const factory = createRegexpValidatorFactory({
    defaultMessages: {
      regexpNotMatch: '',
    },
  });

  return ({ message }: { message?: string } = {}): Validator =>
    factory(emailRegeXp, {
      messages: {
        regexpNotMatch: message || l.t('validation__invalid_email'),
      },
    });
};
