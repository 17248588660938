// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-margin{margin:0!important}.reset-padding{padding:0!important}.entrance-blog{margin:32px 0}.entrance-blog .article-item{background-color:#fff;border-radius:12px;padding:24px}.entrance-blog .article-item:not(:first-child){margin-top:16px}.entrance-blog .article-item__image-preview{-o-object-fit:cover;object-fit:cover;width:100%;height:232px;border-radius:8px}.entrance-blog .article-item__tags{margin-top:20px}.entrance-blog .article-item__tags-item{border-radius:6px;padding:6px 12px;color:#fff;font-size:10px;font-weight:700;letter-spacing:2px;text-transform:uppercase}.entrance-blog .article-item__tags-articles{background-color:#0006ff}.entrance-blog .article-item__tags-updates{background-color:#ffd400}.entrance-blog .article-item__tags-announcements{background-color:red}.entrance-blog .article-item__tags-integrations{background-color:#00ca50}.entrance-blog .article-item__tags-events{background-color:#2fc9ce}.entrance-blog .article-item__tags-cases{background-color:#00a4f7}.entrance-blog .article-item__title{margin-top:16px;font-size:24px;font-weight:800;line-height:28.75px}.entrance-blog .article-item__title a{color:#334150}.entrance-blog .article-item__footer{margin-top:20px;display:flex;align-items:center;justify-content:space-between;color:#7f8fa4}.entrance-blog .article-item__footer-date,.entrance-blog .article-item__footer-read-time{display:flex;align-items:center;grid-gap:8px;gap:8px;font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ALERT_POPUP_WIDTH": "360px",
	"ALERT_POPUP_POSITION_LEFT_OFFSET": "200px",
	"ALERT_POPUP_POSITION_RIGHT_OFFSET": "200px",
	"CHECKBOX_GROUP_GRID_GAP": "16px",
	"CHECKBOX_LABEL_PADDING": "8px",
	"CODE_INPUT_LINE_HEIGHT_SMALL": "20px",
	"CODE_INPUT_VERTICAL_PADDING_SMALL": "9px",
	"CODE_INPUT_LINE_HEIGHT_MINI": "20px",
	"CODE_INPUT_VERTICAL_PADDING_MINI": "8px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_SIZE": "488px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_OFFSET": "24px",
	"IMAGE_UPLOADER_SLIDER_HANDLE_SIZE": "16px",
	"LAYOUT_FOOTER_HEIGHT": "64px",
	"MENU_ITEM_CARET_HEIGHT": "24px",
	"MENU_ITEM_HEIGHT": "32px",
	"radioGroupGridGap": "16px",
	"radioLabelPadding": "8px",
	"TEXTAREA_LINE_HEIGHT_SMALL": "20px",
	"TEXTAREA_VERTICAL_PADDING_SMALL": "9px",
	"TEXTAREA_LINE_HEIGHT_MINI": "20px",
	"TEXTAREA_VERTICAL_PADDING_MINI": "8px",
	"FONT_SIZE_HEADING_1": "24px",
	"FONT_SIZE_HEADING_2": "24px",
	"FONT_SIZE_HEADING_3": "24px",
	"FONT_SIZE_BODY": "14px",
	"FONT_SIZE_LABEL": "12px",
	"FONT_SIZE_CODE": "14px",
	"FONT_SIZE_CODE_SMALL": "12px",
	"FONT_SIZE_TAB_SMALL": "14px",
	"FONT_SIZE_TAB_MINI": "12px",
	"LINE_HEIGHT_HEADING_1": "32px",
	"LINE_HEIGHT_HEADING_2": "24px",
	"LINE_HEIGHT_HEADING_3": "16px",
	"LINE_HEIGHT_BODY": "20px",
	"LINE_HEIGHT_LABEL": "16px",
	"LINE_HEIGHT_CODE": "20px",
	"LINE_HEIGHT_CODE_SMALL": "20px",
	"LINE_HEIGHT_TAB_SMALL": "20px",
	"LINE_HEIGHT_TAB_MINI": "20px"
};
module.exports = ___CSS_LOADER_EXPORT___;
