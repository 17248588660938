import type { Store } from 'vuex';
import { interfaces } from 'inversify';
import { Module, VuexModule, VuexAction, getModule, VuexMutation } from 'nuxt-property-decorator';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { SuppressError } from '@corefy/indicators/utils/SuppressError';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { Nullable } from '@corefy/common/utility-types/Nullable';
import { AuthPayload } from '@/shared/user/store/UserStore';
import { AuthService } from '@/shared/auth/services/AuthService';
import { iocTypes } from '@/shared/ioc/iocTypes';
import {
  Enable2FaRequestPayload,
  UserAttributes,
  UserInvitation,
  UserOrganizationsPayload, UserPreferencesAttributes,
  UserSecret,
} from '@/shared/facade/interfaces/entity/user';
import { UserHttpClient } from '@/shared/facade/services/UserHttpClient';
import { MemberHttpClient } from '@/shared/facade/services/MemberHttpClient';
import { CookieService } from '@/shared/cookie/services/CookieService';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { LOGIN_ROUTE_PATH } from '@/router/consts/route-names';
import { UserStore } from '../UserStore';

export const createUserStoreModule = (ctx: interfaces.Context) => {
  // Consts

  const NAMESPACE = 'UserStore';

  const bindingKeys = {
    logout: `${NAMESPACE}/logout`,
    login: `${NAMESPACE}/login`,
    check2Fa: `${NAMESPACE}/check2Fa`,
    changeUserPassword: `${NAMESPACE}/changeUserPassword`,
    getOrganizations: `${NAMESPACE}/getOrganizations`,
    getInvitations: `${NAMESPACE}/getOrganizations`,
    getUser: `${NAMESPACE}/getUser`,
    updateUserPreferencesData: `${NAMESPACE}/updateUserPreferencesData`,
    getSecret: `${NAMESPACE}/getSecret`,
    getBackupCodes: `${NAMESPACE}/getBackupCodes`,
    switchOrganization: `${NAMESPACE}/switchOrganization`,
    enable2Fa: `${NAMESPACE}/enable2Fa`,
    acceptInvitation: `${NAMESPACE}/acceptInvitation`,
    declineInvitation: `${NAMESPACE}/declineInvitation`,
  };

  // Dependencies
  const indicators = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const store = ctx.container.get<Store<any>>(iocTypes.Store);
  const userHttp = ctx.container.get<UserHttpClient>(iocTypes.UserHttpClient);
  const authService = ctx.container.get<AuthService>(iocTypes.AuthService);
  const memberHttp = ctx.container.get<MemberHttpClient>(iocTypes.MemberHttpClient);
  const cookieService = ctx.container.get<CookieService>(iocTypes.CookieService);
  const appDataStore = ctx.container.get<AppDataStore>(iocTypes.AppDataStore);

  // Module
  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class UserStoreModule extends VuexModule implements UserStore {
    private _user: UserAttributes | null = null;
    private _userId: string | null = null;
    private _authorized = false;
    private _TFARequired = false;
    private _organizations: UserOrganizationsPayload = [];
    private _invitations: UserInvitation[] = [];
    private _secret: UserSecret | null = null;
    private _backupCodes: string[] = [];
    private _authResponse: boolean | null = null;

    get user() {
      return this._user;
    }

    get userId() {
      return this._userId;
    }

    get secret() {
      return this._secret;
    }

    get backupCodes() {
      return this._backupCodes;
    }

    get twoAuthEnabled() {
      return this._authResponse;
    }

    get invitations() {
      return this._invitations;
    }

    get organizations() {
      return this._organizations;
    }

    get authorized() {
      return this._authorized;
    }

    get TFARequired() {
      return this._TFARequired;
    }

    get loginIndicators() {
      return indicators.getIndicators(bindingKeys.login);
    }

    get logoutIndicators() {
      return indicators.getIndicators(bindingKeys.logout);
    }

    get check2FaIndicators() {
      return indicators.getIndicators(bindingKeys.check2Fa);
    }

    get changePasswordIndicators() {
      return indicators.getIndicators(bindingKeys.changeUserPassword);
    }

    get getOrganizationsIndicators() {
      return indicators.getIndicators(bindingKeys.getOrganizations);
    }

    get getInvitationsIndicators() {
      return indicators.getIndicators(bindingKeys.getInvitations);
    }

    get getUserIndicators() {
      return indicators.getIndicators(bindingKeys.getUser);
    }

    get getSecretIndicators() {
      return indicators.getIndicators(bindingKeys.getSecret);
    }

    get getBackupCodesIndicators() {
      return indicators.getIndicators(bindingKeys.getBackupCodes);
    }

    get switchOrganizationIndicators() {
      return indicators.getIndicators(bindingKeys.switchOrganization);
    }

    get enable2FaIndicators() {
      return indicators.getIndicators(bindingKeys.enable2Fa);
    }

    get acceptInvitationIndicators() {
      return indicators.getIndicators(bindingKeys.acceptInvitation);
    }

    get declineInvitationIndicators() {
      return indicators.getIndicators(bindingKeys.declineInvitation);
    }

    get updateUserPreferencesDataIndicators() {
      return indicators.getIndicators(bindingKeys.updateUserPreferencesData);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.check2Fa)
    async check2Fa(payload: { auth_code: string }) {
      const resp = await authService.check2Fa(payload);

      this.setAuthState(resp);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.login)
    async login({ login, password }: AuthPayload) {
      const resp = await authService.login({ login, password });
      this.setAuthState(resp);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.logout)
    async logout() {
      await authService.logout();

      this.setAuthState({
        authorized: false,
        TFARequired: false,
      });

      cookieService.removeCookie('loggedIn', {
        domain: appDataStore.data.env.COOKIE_DOMAIN,
      });

      window.location.href = LOGIN_ROUTE_PATH;
    }

    @VuexMutation
    setAuthState(p: { authorized: boolean; TFARequired: boolean }) {
      this._authorized = p.authorized;
      this._TFARequired = p.TFARequired;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getOrganizations)
    async getOrganizations() {
      const resp = await userHttp.getOrganizations();
      this._setOrganizations(resp.data);
    }

    @VuexMutation
    private _setOrganizations(organizations: UserOrganizationsPayload) {
      this._organizations = organizations;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getInvitations)
    async getInvitations() {
      const resp = await userHttp.getInvitations();
      this._setInvitations(resp.data.data);
    }

    @VuexMutation
    private _setInvitations(invitations: Array<UserInvitation>) {
      this._invitations = invitations;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getUser)
    async getUser() {
      const res = await userHttp.getUser();
      this._setUser(res.data.data.attributes);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.updateUserPreferencesData)
    async updateUserPreferencesData({
        locale,
        time_zone,
        data_grid_items_count,
        number_format,
        userRelativeTime,
        date_format,
        test_mode_on_sidebar,
        time_format,
      }: Partial<Nullable<UserPreferencesAttributes>>) {
      await userHttp.updateUser({
        data: {
          type: 'user',
          attributes: {
            settings: {
              preferences: {
                locale,
                time_zone,
                data_grid_items_count,
                number_format,
                user_relative_time: userRelativeTime,
                date_format,
                test_mode_on_sidebar,
                time_format,
              },
            },
          },
        },
      });
    }

    @VuexMutation
    private _setUser(user: UserAttributes) {
      this._user = user;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getSecret)
    async getSecret() {
      const resp = await userHttp.getSecret();
      this._setSecret(resp.data.data);
    }

    @VuexMutation
    private _setSecret(secret: UserSecret) {
      this._secret = secret;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getBackupCodes)
    async getBackupCodes() {
      const resp = await userHttp.getBackupCodes();
      this._setBackupCodes(resp.data.data.backupCodes);
    }

    @VuexMutation
    private _setBackupCodes(codes: Array<string>) {
      this._backupCodes = codes;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.switchOrganization)
    async switchOrganization(id: string) {
      const data = {
        type: '-',
        id,
      };
      await userHttp.switchOrganization(id, data);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.enable2Fa)
    async enable2Fa(payload: Enable2FaRequestPayload) {
      const resp = await userHttp.enable2Fa(payload);
      this._setAuthResponse(resp.data.data.status);

      const userResp = await userHttp.getUser();
      this._setUser(userResp.data.data.attributes);
    }

    @VuexMutation
    private _setAuthResponse(authResponse: boolean | null) {
      this._authResponse = authResponse;
    }

    @VuexAction
    clearAuthResponse() {
      this._setAuthResponse(null);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.acceptInvitation)
    async acceptInvitation(id: string) {
      await memberHttp.acceptInvitation(id);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.declineInvitation)
    async declineInvitation(id: string) {
      await memberHttp.declineInvitation(id);
    }
  }

  return getModule(UserStoreModule);
};
