import { Middleware } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { Alert } from '@/shared/global-alerts/enumerations/alerts';
import { RegistrationStore } from '@/shared/registration/RegistrationStore';
import { tokenExpiredRegexp } from '@/shared/regexps/tokenExpired';
import { Modal } from '@/shared/global-modals/enumerations/modals';
import { TOKEN_NOT_FOUND_OR_USED } from '@/shared/errors/const/httpErrorsMap';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

const getError = (token: string | undefined, email: string | undefined) => {
  return token && email ? Alert.SomethingWentWrong : Alert.IncorrectLink;
};

const middleware: Middleware = async ({ $container, route, redirect }) => {
  const appData = $container.get<AppDataStore>(iocTypes.AppDataStore);

  if (appData.data.env.REGISTRATION_DISABLED === 'true') {
    return;
  }

  const token = route.query.token as string;
  const email = route.query.email as string;

  if (!token && !email) {
    redirect({
      name: 'login',
      query: {
        alert: getError(email, token),
      },
    });
  }
  const registrationStore = $container.get<RegistrationStore>(iocTypes.RegistrationStore);
  await registrationStore.registrationConfirmation({ token });

  const { success, error } = registrationStore.registrationConfirmationIndicators;
  if (success) {
    redirect({
      name: 'login',
      query: {
        activated: email,
      },
    });
  } else if (error) {
    const errorMsg = Array.isArray(error.details) ? error.details.join('. ') : error.details;
    if (errorMsg && tokenExpiredRegexp.test(errorMsg)) {
      redirect({
        name: 'login',
        query: {
          resend: email,
          modal: Modal.ConfirmationTokenExpired,
        },
      });
    } else if (error.name === TOKEN_NOT_FOUND_OR_USED) {
      redirect({
        name: 'login',
        query: {
          resend: email,
          alert: Alert.InviteNotFoundOrRevoked,
        },
      });
    } else {
      redirect({
        name: 'login',
        query: {
          alert: getError(email, token),
        },
      });
    }
  } else {
    redirect({
      name: 'login',
      query: {
        alert: getError(email, token),
      },
    });
  }
};

export default middleware;
