// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/tt-norms-pro/TTNormsPro-Regular.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/tt-norms-pro/TTNormsPro-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/tt-norms-pro/TTNormsPro-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/tt-norms-pro/TTNormsPro-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/tt-norms-pro/TTNormsPro-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/tt-norms-pro/TTNormsPro-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./fonts/tt-norms-pro/TTNormsPro-ExtraBold.eot");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./fonts/tt-norms-pro/TTNormsPro-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./fonts/tt-norms-pro/TTNormsPro-ExtraBold.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-margin{margin:0!important}.reset-padding{padding:0!important}.ui-form .ui-form-item{margin-bottom:32px}.ui-form .ui-form-controls{margin-top:48px;padding-top:0}.entrance__footer{width:100%;margin-bottom:36px;box-sizing:border-box}.entrance__footer .footer{display:flex;justify-content:space-between;align-items:flex-end}.entrance__footer .footer .footer__links{display:flex;grid-column-gap:16px;-moz-column-gap:16px;column-gap:16px}.entrance--mono .entrance__footer{padding:0 142px}@font-face{font-family:\"TT Norms Pro\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"TT Norms Pro\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:\"TT Norms Pro\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\");font-weight:800;font-style:normal}.form-suffix{display:flex;justify-content:center;align-items:center;grid-column-gap:8px;-moz-column-gap:8px;column-gap:8px;margin-top:24px}.form-suffix>p{font-family:\"Roboto\",\"Arial\",sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:16px;letter-spacing:.5px;text-align:left}.form-suffix__link{font-weight:500!important}.fw--medium{font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ALERT_POPUP_WIDTH": "360px",
	"ALERT_POPUP_POSITION_LEFT_OFFSET": "200px",
	"ALERT_POPUP_POSITION_RIGHT_OFFSET": "200px",
	"CHECKBOX_GROUP_GRID_GAP": "16px",
	"CHECKBOX_LABEL_PADDING": "8px",
	"CODE_INPUT_LINE_HEIGHT_SMALL": "20px",
	"CODE_INPUT_VERTICAL_PADDING_SMALL": "9px",
	"CODE_INPUT_LINE_HEIGHT_MINI": "20px",
	"CODE_INPUT_VERTICAL_PADDING_MINI": "8px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_SIZE": "488px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_OFFSET": "24px",
	"IMAGE_UPLOADER_SLIDER_HANDLE_SIZE": "16px",
	"LAYOUT_FOOTER_HEIGHT": "64px",
	"MENU_ITEM_CARET_HEIGHT": "24px",
	"MENU_ITEM_HEIGHT": "32px",
	"radioGroupGridGap": "16px",
	"radioLabelPadding": "8px",
	"TEXTAREA_LINE_HEIGHT_SMALL": "20px",
	"TEXTAREA_VERTICAL_PADDING_SMALL": "9px",
	"TEXTAREA_LINE_HEIGHT_MINI": "20px",
	"TEXTAREA_VERTICAL_PADDING_MINI": "8px",
	"FONT_SIZE_HEADING_1": "24px",
	"FONT_SIZE_HEADING_2": "24px",
	"FONT_SIZE_HEADING_3": "24px",
	"FONT_SIZE_BODY": "14px",
	"FONT_SIZE_LABEL": "12px",
	"FONT_SIZE_CODE": "14px",
	"FONT_SIZE_CODE_SMALL": "12px",
	"FONT_SIZE_TAB_SMALL": "14px",
	"FONT_SIZE_TAB_MINI": "12px",
	"LINE_HEIGHT_HEADING_1": "32px",
	"LINE_HEIGHT_HEADING_2": "24px",
	"LINE_HEIGHT_HEADING_3": "16px",
	"LINE_HEIGHT_BODY": "20px",
	"LINE_HEIGHT_LABEL": "16px",
	"LINE_HEIGHT_CODE": "20px",
	"LINE_HEIGHT_CODE_SMALL": "20px",
	"LINE_HEIGHT_TAB_SMALL": "20px",
	"LINE_HEIGHT_TAB_MINI": "20px"
};
module.exports = ___CSS_LOADER_EXPORT___;
