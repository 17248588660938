import { ErrorData } from '@/shared/errors/interfaces/Error';

const errorCheck = <T extends ErrorData>(errorMsg: string, errors: Array<T>): T | null => {
  const error = errors.find((error) =>
    error.regexp ? error.regexp!.test(errorMsg) : error.message === errorMsg
  );

  return error || null;
};

export { errorCheck };
