/**
 * This regular expression is designed to validate email addresses based on the RFC 5322 and RFC 6530 standards. RFC 5322 specifies the format for Internet Message (email) syntax, while RFC 6530 introduces the support for internationalized email addresses using non-Latin and non-ASCII characters.
 *
 * The email addresses validated by this regex include:
 *
 * Standard ASCII email addresses, such as "john.doe@example.com" and "jane_doe@example.co.uk".
 * Email addresses with international characters in both the local and domain parts, such as "олександр@мояпошта.ua", "δοκιμή@παράδειγμα.δοκιμή", and "संपर्क@डाटामेल.भारत".
 * The supported characters in the email addresses include:
 *
 * Latin letters (A-Z, a-z)
 * Digits (0-9)
 * International characters, including non-Latin letters and Unicode characters
 * Special characters, such as !, #, $, %, &, *, +, -, /, =, ?, ^, _, `, {, |, }, and ~
 */

export const emailRegeXp =
  /^(([\p{L}\p{M}\p{N}\p{Pc}!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}\p{M}\p{N}\p{Pc}!#$%&'*+\/=?^_`{|}~-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([\p{L}\p{M}\p{N}\p{Pc}+-]+\.)+[\p{L}\p{M}]{2,}))$/u;
