import { inject, interfaces } from 'inversify';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { Store } from 'vuex';
import { BlogHttpClient } from '@/shared/blog/services/BlogHttpClient';
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { BlogStore, Post } from '@/shared/blog/store/BlogStore';
import { SuppressError } from '@corefy/indicators/utils/SuppressError';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

export const createBlogStoreModule = (ctx: interfaces.Context) => {
  const NAMESPACE = 'BlogStore';

  const bindingKeys = {
    getPosts: `${NAMESPACE}/getPosts`,
  };

  const indicators = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const store = ctx.container.get<Store<any>>(iocTypes.Store);
  const blogHttp = ctx.container.get<BlogHttpClient>(iocTypes.BlogHttpClient);
  const appDataStore = ctx.container.get<AppDataStore>(iocTypes.AppDataStore);

  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class BlogStoreModule extends VuexModule implements BlogStore {
    private _posts: Post[] = [];
    private _pageNumber: number | null = null;

    get posts() {
      return this._posts;
    }

    get pageNumber() {
      return this._pageNumber;
    }

    get postsIndicators() {
      return indicators.getIndicators(bindingKeys.getPosts);
    }

    get isBlogHidden() {
      return !appDataStore.data.env.BLOG_URL;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getPosts)
    async getPosts(page?: number) {
      const res = await blogHttp.getPosts(page);
      this._setBlogPosts(res.data.data);
      this._setPageNumber(res.data.meta.page.number + 1);
    }

    @VuexMutation
    private _setBlogPosts(posts: any) {
      this._posts = this._posts.concat(posts);
    }

    @VuexMutation
    private _setPageNumber(pageNumber: number) {
      this._pageNumber = pageNumber;
    }
  }

  return getModule(BlogStoreModule);
};
