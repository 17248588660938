import { iocTypes } from '@/shared/ioc/iocTypes';
import { AuthService, AuthResult, AuthPayload } from '@/shared/auth/services/AuthService';
import { inject, injectable } from 'inversify';
import { Context } from '@nuxt/types';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { UserHttpClient } from '@/shared/facade/services/UserHttpClient';

@injectable()
export class DefaultAuthService implements AuthService {
  constructor(
    @inject(iocTypes.SystemHttpClient)
    private readonly _systemHttpClient: SystemHttpClient,
    @inject(iocTypes.UserHttpClient)
    private readonly _userHttpClient: UserHttpClient,
    @inject(iocTypes.AppDataStore)
    private readonly _appDataStore: AppDataStore
  ) {}

  async login({ login, password }: AuthPayload): Promise<AuthResult> {
    let authRes;

    try {
      const response = await this._systemHttpClient.login({
        email: login,
        password,
      });

      authRes = response.data;

      if (authRes && authRes.success) {
        return this._getAuthorized();
      }

      return this._getNotAuthorized();
    } catch (e: any) {
      const { data = null } = e.response;

      if (data && !data.success) {
        if (data['2fa']) {
          return this._getNotAuthorizedWith2Fa();
        }
      }

      throw e;
    }
  }

  async logout(): Promise<AuthResult> {
    await this._systemHttpClient.logout();
    return this._getNotAuthorized();
  }

  async check2Fa(payload: { auth_code: string }) {
    const authRes = await this._systemHttpClient.check2Fa(payload);

    if (authRes.data.success) {
      return this._getAuthorized();
    }

    return this._getNotAuthorizedWith2Fa();
  }

  async checkAuthorization(ctx: Context): Promise<AuthResult> {
    try {
      const authCookie = ctx.app.$cookies.get(this._appDataStore.data.env.AUTH_COOKIE_NAME);

      if (!authCookie) {
        return this._getNotAuthorized();
      }

      await this._userHttpClient.getUser();

      return this._getAuthorized();
    } catch (e) {
      return this._getNotAuthorized();
    }
  }

  private _getNotAuthorized(): AuthResult {
    return {
      authorized: false,
      TFARequired: false,
    };
  }

  private _getNotAuthorizedWith2Fa(): AuthResult {
    return {
      authorized: false,
      TFARequired: true,
    };
  }

  private _getAuthorized(): AuthResult {
    return {
      authorized: true,
      TFARequired: false,
    };
  }
}
