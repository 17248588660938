const Alert = {
  SomethingWentWrong: 'something_wrong' as const,
  IncorrectLink: 'incorrect_link' as const,
  InviteHasExpired: 'invite_has_expired' as const,
  InviteNotFoundOrRevoked: 'invite_not_found_or_revoked' as const,
  TokenNotFoundOrUsed: 'token_not_found_or_used' as const,
  PasswordChanged: 'password_changed' as const,
};

type Alert = typeof Alert[keyof typeof Alert];

export { Alert };
