import { ErrorHandler } from '@corefy/error/services/ErrorProcessor';
import { ErrorInfo } from '@corefy/error/interfaces/ErrorInfo';
import { ErrorReporter } from '@corefy/error/services/ErrorReporter';
import { AxiosError } from 'axios';
import { errorCheck } from '@/shared/utils/entryСheck';
import { httpErrorsMap } from '@/shared/errors/const/httpErrorsMap';

export class DefaultHttpErrorsHandler implements ErrorHandler<AxiosError> {
  constructor(reporter: ErrorReporter) {
    this._reporterError = reporter;
  }

  private _reporterError: ErrorReporter | null = null;

  // Check if the error is equal to one of the types
  isMatch(error: Error): error is AxiosError {
    return (error as AxiosError).isAxiosError;
  }

  // Getting error information in "human language"
  getInfo(error: AxiosError): ErrorInfo {
    const { response } = error;
    const extractedErrorMsg = this._extractError(error);
    const detectedErrorData = errorCheck(extractedErrorMsg, httpErrorsMap);

    return {
      name: detectedErrorData ? detectedErrorData.title : error.name || 'Error',
      details: detectedErrorData ? detectedErrorData.details : extractedErrorMsg,
      code: response?.status,
    };
  }

  // Error handler. The alert service is used here
  async handleError(error: AxiosError): Promise<void> {
    if (process.client) {
      const { name, details } = this.getInfo(error);
      const message = Array.isArray(details) ? details.join('. ') : details || '';

      await this._reporterError!.report({ name, message } as Error);
    }
  }

  private _extractError(error: AxiosError) {
    if (error.response?.data.message) {
      return error.response.data.message;
    } else if (error.response?.data.errors) {
      const { errors } = error.response.data;
      if (Array.isArray(errors)) {
        const mergedErrors = errors
          .map((error: any) => {
            return error.title;
          })
          .filter((error: any) => error);

        return mergedErrors.join('. ');
      } else {
        return Object.values(errors).join('. ');
      }
    }

    return error.message;
  }
}
