import { interfaces } from 'inversify';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { Store } from 'vuex';
import { OrganizationStore } from '@/shared/organization/store/OrganizationStore';
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { SuppressError } from '@corefy/indicators/utils/SuppressError';
import { OrganizationHttpClient } from '@/shared/facade/services/OrganizationHttpClient';
import {
  OrganizationCreatePayload,
  JsonApiOrganization,
} from '@/shared/facade/interfaces/entity/organization';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';

export const createOrganizationStoreModule = (ctx: interfaces.Context) => {
  //Consts

  const NAMESPACE = 'OrganizationStore';
  const SYSTEM_CURRENCIES_ENUM = 'system-currencies';

  const bindingKeys = {
    getOrganization: `${NAMESPACE}/getOrganization`,
    createOrganization: `${NAMESPACE}/createOrganization`,
    initialize: `${NAMESPACE}/initialize`,
  };

  // Dependencies
  const indicators = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const store = ctx.container.get<Store<any>>(iocTypes.Store);
  const systemHttp = ctx.container.get<SystemHttpClient>(iocTypes.SystemHttpClient);
  const organizationHttp = ctx.container.get<OrganizationHttpClient>(
    iocTypes.OrganizationHttpClient
  );

  // Module
  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class OrganizationStoreModule extends VuexModule implements OrganizationStore {
    private _organization: JsonApiOrganization | null = null;
    private _currencies: any = [];

    get currencies() {
      return this._currencies;
    }

    get organization() {
      return this._organization;
    }

    get getOrganizationIndicators() {
      return indicators.getIndicators(bindingKeys.getOrganization);
    }

    get initializeIndicators() {
      return indicators.getIndicators(bindingKeys.initialize);
    }

    get createOrganizationIndicators() {
      return indicators.getIndicators(bindingKeys.createOrganization);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.getOrganization)
    async getOrganization() {
      const res = await organizationHttp.getOrganization();
      this._setOrganization(res.data.data);
    }

    @VuexMutation
    private _setOrganization(organization: JsonApiOrganization) {
      this._organization = organization;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.initialize)
    async initialize() {
      const res = await systemHttp.getEnumeration({ name: SYSTEM_CURRENCIES_ENUM });
      this._setCurrencies(res.data.data.enumerations[SYSTEM_CURRENCIES_ENUM].map((c) => c.value));
    }

    @VuexMutation
    private _setCurrencies(currencies: any) {
      this._currencies = currencies;
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.createOrganization)
    async createOrganization(data: OrganizationCreatePayload) {
     await organizationHttp.createOrganization(data);
    }
  }
  return getModule(OrganizationStoreModule);
};
