















import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';

@Component
export default class GoBackComponent extends Vue {
  private additionalSlot() {
    return !!this.$slots['additional-content'];
  }
}
