import { inject, injectable } from 'inversify';
import {
  LocationConfig,
  LocationManager,
} from '@/shared/router/services/LocationManager';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

@injectable()
export class ClientLocationManager implements LocationManager {
  constructor(@inject(iocTypes.AppDataStore) private readonly appDataStore: AppDataStore) {}

  // Redirect to default route to show skeleton and redirect to the previous route without double redirect
  goToDashboard(config: LocationConfig) {
    const url = this.resolveDashboardUrl(config)
    location.replace(url);
  }

  resolveDashboardUrl({ path = '', alias }: LocationConfig) {
    const defaultHost = this.appDataStore.data.env.APP_HOST;

    const { protocol } = location;
    const editedPath = path && path[0] === '/' ? path.slice(1) : path;

    return `${protocol}//${alias}.${defaultHost}/${editedPath}`;
  }
}
