import { Plugin } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { ErrorProcessor } from '@corefy/error/services/ErrorProcessor';
import { ErrorInfo } from '@corefy/indicators/interfaces/IndicatorsSet';

const plugin: Plugin = async (ctx) => {
  const indicatorsService = ctx.$container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const errorProcessor = ctx.$container.get<ErrorProcessor>(iocTypes.ErrorProcessor);

  indicatorsService.onError.addListener(({ error, info }: { error: Error; info: ErrorInfo }) => {
    errorProcessor.process(error as Error);
  });
};

export default plugin;
