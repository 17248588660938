






import { Vue, Component } from 'nuxt-property-decorator';

type ScreenWidthSize = 'small' | 'medium' | 'large';

@Component
export default class AdaptiveContent extends Vue {
  private screenWidth: number | null = null;
  private isReady = false;

  private created() {
    if (process.client) {
      this.syncScreenWidth();
    }
  }

  private mounted() {
    this.syncScreenWidth();

    const handler = () => this.syncScreenWidth();
    window.addEventListener('resize', handler);
    this.$on('hook:beforeDestroy', () => window.removeEventListener('resize', handler));
  }

  private get slotScope() {
    return {
      screenWidth: this.screenWidth,
      isWidthLarge: this.screenWidthSize === 'large',
      isWidthMedium: this.screenWidthSize === 'medium',
      isWidthSmall: this.screenWidthSize === 'small',
    };
  }

  private get screenWidthSize(): ScreenWidthSize | null {
    if (!this.screenWidth) return null;
    if (this.screenWidth >= 1440) return 'large';
    if (this.screenWidth >= 1024) return 'medium';

    return 'small';
  }

  private syncScreenWidth() {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];

    this.screenWidth = w.innerWidth || e.clientWidth || g.clientWidth;
    this.isReady = true;
  }
}
