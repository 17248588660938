








































import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';
import { ResettingPassStore } from '@/shared/resetting-password/ResettingPassStore';

@Component
export default class ResetPassTokenExpired extends Vue {
  @inject(iocTypes.ResettingPassStore)
  private readonly resetStore!: ResettingPassStore;

  @inject(iocTypes.ErrorAlertService)
  private readonly errorAlertService!: ErrorAlertService;

  @Prop({ type: Boolean, default: false })
  private readonly value!: boolean;

  created() {
    this.setResendEmail();
  }

  mounted() {
    this.visible = this.model;
  }

  private visible = false;
  private email = '';
  private resend = false;

  private get model() {
    return this.value;
  }

  private set model(value: boolean) {
    this.$emit('input', value);
  }

  private get resetIndicators() {
    return this.resetStore.resettingPassIndicators;
  }

  private async closedHandler() {
    this.model = false;
    if (!this.resend) return;

    await this.resetStore.resettingPass({ email: this.email });

    if (this.resetIndicators.success) {
      await this.$router.push({
        name: 'resend-link',
        query: {
          email: this.email,
        },
      });
    } else if (this.resetIndicators.error) {
      this.errorAlertService.showError({ error: this.resetIndicators.error });
    }
  }

  private close() {
    this.visible = false;
  }

  private setResendEmail() {
    const { resend } = this.$route.query;
    if (resend) {
      this.email = resend as string;
    } else {
      console.error('Resend email was not found');
    }
  }

  private async resendHandler() {
    this.resend = true;
    this.close();
  }
}
