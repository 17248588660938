import { en as corefyUiTranslations } from '@corefy/ui/consts/i18n/en';
import { resolveTranslationsFromContext } from '@/shared/localization/utils/resolveTranslationsFromContext';

const shared = resolveTranslationsFromContext(require.context('./', true, /\.json$/));
const modules = resolveTranslationsFromContext(require.context('../../modules', true, /en\.json$/));

export default {
  ...corefyUiTranslations,
  ...shared,
  ...modules,
};
