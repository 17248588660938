










import Vue from 'vue';
import { Component, Prop } from 'nuxt-property-decorator';

@Component
export default class FormHeader extends Vue {
  @Prop({ type: String })
  private readonly title!: string;

  @Prop({ type: String })
  private readonly subtitle?: string;

  private subtitleSlotPresent() {
    return !!this.$slots.subtitle;
  }
}
