import "reflect-metadata"

import { LazyContainer } from "~inversify-nuxt/runtime"

import injectBase from '@/shared/ioc/baseContainer.ts'
import injectSpecific from '@/shared/ioc/clientContainer.ts'

export default async (ctx, inject) => {
  const container = new LazyContainer()
  injectBase(ctx, container)
	injectSpecific(ctx, container)

  ctx.$container = container
  inject("container", container)
};