const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['join'] = require('../middleware/join.ts')
middleware['join'] = middleware['join'].default || middleware['join']

middleware['registration-confirmation'] = require('../middleware/registration-confirmation.ts')
middleware['registration-confirmation'] = middleware['registration-confirmation'].default || middleware['registration-confirmation']

export default middleware
