import { ErrorsMap } from '@/shared/errors/interfaces/Error';

export const UNVERIFIED_EMAIL = 'error__unverified_email';
export const TOKEN_NOT_FOUND_OR_USED = 'error__token_not_found_or_used';

const httpErrorsMap: Array<ErrorsMap> = [
  {
    regexp: new RegExp(/^(?=.*\bUser\b)(?=.*\bexists\b).*$/),
    title: 'error__user_exists',
    details: 'error__user_exists__description',
  },
  {
    regexp: new RegExp(/^(?=.*\bThe token\b)(?=.*\bdoes not exist\b).*$/),
    title: TOKEN_NOT_FOUND_OR_USED,
    details: 'error__token_not_found_or_used__description',
  },
  {
    message: 'Bad credentials.',
    title: 'error__invalid_credentials',
    details: 'error__invalid_credentials__description',
  },
  {
    message: 'The presented password is invalid.',
    title: 'error__invalid_credentials',
    details: 'error__invalid_credentials__description',
  },
  {
    message: 'Email not confirmed.',
    title: UNVERIFIED_EMAIL,
    details: 'error__unverified_email__description',
  },
];

export { httpErrorsMap };
