import { Validator } from '@corefy/validation/interfaces/Validator';
import { createRegexpValidatorFactory } from '@corefy/validation/services/implementations/RegexpValidator';
import { phoneRegeXp } from '@/shared/regexps/Phone';
import { interfaces } from 'inversify';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { iocTypes } from '@/shared/ioc/iocTypes';

export interface PhoneValidator {
  (): Validator;
}

export interface PhoneValidatorFactory {
  (options?: { message?: string }): Validator;
}

export const createPhoneValidatorFactory = (ctx: interfaces.Context): PhoneValidatorFactory => {
  const l = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);
  const factory = createRegexpValidatorFactory({
    defaultMessages: {
      regexpNotMatch: '',
    },
  });

  return ({ message }: { message?: string } = {}): Validator =>
    factory(phoneRegeXp, {
      messages: {
        regexpNotMatch: message || l.t('validation__invalid_phone'),
      },
    });
};
