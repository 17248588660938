import Vue from 'vue';
import FieldValidation from '@corefy/vue-validation/components/FieldValidation';
import FormValidation from '@corefy/vue-validation/components/FormValidation';
import PortalSource from '@/shared/ui/portal/PortalSource.vue';
import PortalDestination from '@/shared/ui/portal/PortalDestination.vue';
import AdaptiveContent from '@/shared/ui/AdaptiveContent.vue';
import LangSwitcher from '@/shared/ui/LangSwitcher.vue';
import DefaultFooter from '@/shared/ui/DefaultFooter.vue';

// Entrance imports
import GoBackComponent from '@/modules/entrance/components/GoBackComponent.vue';
import FormHeader from '@/modules/entrance/components/FormHeader.vue';

const components = {
  // ========= General components =======================
  LangSwitcher,

  // Validation
  FieldValidation,
  FormValidation,
  // ========= Entrance global components ===============
  GoBackComponent,

  // Form
  FormHeader,

  // ========= Loggia global components =================
  AdaptiveContent,
  DefaultFooter,

  // Portal
  PortalSource,
  PortalDestination,
};

Object.entries(components).forEach(([name, component]) => Vue.component(name, component));
