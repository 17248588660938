




import { Vue, Component } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

@Component
export default class AppLogo extends Vue {
  @inject(iocTypes.AppDataStore)
  private readonly appData!: AppDataStore;

  get logoUrl() {
    return this.appData.data.env.LOGO_URL || '/logo.svg';
  }
}
