import Vue from 'vue';
import Router from 'vue-router';
import { flattenRoutes } from '@corefy/routing-nuxt/utils/flattenRoutes';
import { stringifyQuery, parseQuery } from '@corefy/routing/utils/query';
import routes from './routes';

Vue.use(Router);

export const createRouter = (): Router => {
  const transformedRoutes = flattenRoutes(routes);
  return new Router({
    mode: 'history',
    parseQuery,
    stringifyQuery,
    routes: transformedRoutes,
  });
};
