




import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { portal } from '@/shared/portals/enum/portal';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { PortalKeys, PortalsStore } from '@/shared/portals/Portals';

@Component
export default class PortalDestination extends Vue {
  @inject(iocTypes.PortalsStore)
  private readonly portalsStore!: PortalsStore;

  @Prop({
    type: String,
    required: true,
    validator: (value: any) => Object.values(portal).includes(value),
  })
  private readonly destination!: PortalKeys;

  handleChange(contentPresent: boolean) {
    //Remove portal from the store
    this.portalsStore.setItem(this.destination, contentPresent);
  }
}
