import {
  AuthRedirectService,
  RedirectDestination,
} from '@/shared/auth/services/AuthRedirectService';
import { injectable } from 'inversify';
import { RouteType } from '@/router/enum/RouteType';
import { Route } from 'vue-router';

const ORGANIZATIONS_LIST_ROUTE = 'org-list';
const LOGIN_ROUTE = 'login';
const CREATE_ORGANIZATION_ROUTE = 'create-organization';

@injectable()
export class DefaultAuthRedirectService implements AuthRedirectService {
  resolveRedirect({
    authorized,
    route,
    noOrganizations = false,
  }: {
    authorized: boolean;
    route: Route;
    noOrganizations: boolean;
  }): RedirectDestination {
    const meta = Array.isArray(route.meta) ? route.meta[0] : route.meta;

    if (authorized) {
      if (noOrganizations) {
        return {
          httpCode: 302,
          redirectTo: CREATE_ORGANIZATION_ROUTE,
          children: ['prepare-organization'],
        };
      } else {
        const unauthorizedRouteType = meta?.type === RouteType.Unauthorized;
        return unauthorizedRouteType || !route.name
          ? {
              httpCode: 302,
              redirectTo: ORGANIZATIONS_LIST_ROUTE,
            }
          : {};
      }
    } else if (!authorized) {
      const authorizedRouteType = meta?.type === RouteType.Authorized;

      return authorizedRouteType || !route.name
        ? {
            httpCode: 302,
            redirectTo: LOGIN_ROUTE,
          }
        : {};
    }
    return {};
  }
}
