import type { Store } from 'vuex';
import { interfaces } from 'inversify';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { Module, VuexModule, getModule, VuexMutation } from 'nuxt-property-decorator';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { AppData } from '@/shared/app-data/interfaces/AppData';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

export const createAppDataStore = (ctx: interfaces.Context) => {
  // Consts
  const NAMESPACE = 'AppDataStore';
  const ERROR_NAMESPACE = 'AppDataStore';

  // Dependencies
  const store = ctx.container.get<Store<any>>(iocTypes.Store);

  // Module
  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class AppDataStoreModule extends VuexModule implements AppDataStore {
    private _appData: AppData | null = null;

    get data(): AppData {
      if (!this._appData) {
        throw new Error(
          `${ERROR_NAMESPACE}: app data is not setted. Do you try to access app data before it initialised in plugin?`
        );
      }

      return this._appData;
    }

    @VuexMutation
    setData(appData: AppData) {
      this._appData = appData;
    }
  }

  return getModule(AppDataStoreModule);
};
