import { inject, injectable } from 'inversify';
import { OrganizationHttpClient } from '@/shared/facade/services/OrganizationHttpClient';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { HttpClient, HttpClientFactory } from '@corefy/http/services/HttpClient';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { getBaseFacadeClientConfig } from '@/shared/facade/consts/facadeClientConfig';
import {
  OrganizationCreatePayload,
  OrganizationCreateValidationPayload
} from '@/shared/facade/interfaces/entity/organization';

@injectable()
export class DefaultOrganizationHttpClient implements OrganizationHttpClient {
  constructor(
    @inject(iocTypes.HttpClientFactory) createHttpClient: HttpClientFactory,
    @inject(iocTypes.AppDataStore)
    appDataStore: AppDataStore
  ) {
    const { FACADE_URL } = appDataStore.data.env;
    this._httpClient = createHttpClient(getBaseFacadeClientConfig(FACADE_URL));
  }

  private _httpClient: HttpClient;

  getOrganization() {
    return this._httpClient.get('/organization');
  }

  createOrganization(data: OrganizationCreatePayload) {
    return this._httpClient.post('/organization', { data });
  }

  validateOrganizationCreation(
    params: OrganizationCreateValidationPayload
  ) {
    return this._httpClient.post<void>('/organization/validate', undefined, { params });
  }
}
