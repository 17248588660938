import merge from 'lodash/merge';
import { Plugin } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

const plugin: Plugin = async (ctx) => {
  const appDataStore = ctx.$container.get<AppDataStore>(iocTypes.AppDataStore);

  appDataStore.setData(
    merge(appDataStore.data, {
      env: {
        FACADE_URL: `${window.location.origin}/api/v1`,
      },
    })
  );
};
export default plugin;
