import { ErrorReporter } from '@corefy/error/services/ErrorReporter';
import { ErrorHandler } from '@corefy/error/services/ErrorProcessor';

export class DefaultErrorsHandle implements ErrorHandler {
  private _reporterError: ErrorReporter | null = null;

  constructor(reporter: ErrorReporter) {
    this._reporterError = reporter;
  }

  isMatch(error: Error): error is Error {
    return true;
  }

  getInfo(e: Error) {
    return { name: e.name, details: e.message ? e.message : undefined };
  }

  async handleError(error: Error) {
    //Handle error
  }
}
