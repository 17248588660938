import { AppLocale } from '@/shared/localization/enumerations/AppLocale';

export const localesAssetsMap: {
  [K in AppLocale]: {
    translationKey: string;
  };
} = {
  [AppLocale.English]: {
    translationKey: 'common__english',
  },
  [AppLocale.Ukrainian]: {
    translationKey: 'common__ukrainian',
  },
};
