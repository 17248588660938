// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-margin{margin:0!important}.reset-padding{padding:0!important}*{list-style-type:none;text-decoration:none}*,body,html{font-family:\"Roboto\",sans-serif}body,html{color:#334150;scroll-behavior:smooth;height:100%;overflow:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ALERT_POPUP_WIDTH": "360px",
	"ALERT_POPUP_POSITION_LEFT_OFFSET": "200px",
	"ALERT_POPUP_POSITION_RIGHT_OFFSET": "200px",
	"CHECKBOX_GROUP_GRID_GAP": "16px",
	"CHECKBOX_LABEL_PADDING": "8px",
	"CODE_INPUT_LINE_HEIGHT_SMALL": "20px",
	"CODE_INPUT_VERTICAL_PADDING_SMALL": "9px",
	"CODE_INPUT_LINE_HEIGHT_MINI": "20px",
	"CODE_INPUT_VERTICAL_PADDING_MINI": "8px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_SIZE": "488px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_OFFSET": "24px",
	"IMAGE_UPLOADER_SLIDER_HANDLE_SIZE": "16px",
	"LAYOUT_FOOTER_HEIGHT": "64px",
	"MENU_ITEM_CARET_HEIGHT": "24px",
	"MENU_ITEM_HEIGHT": "32px",
	"radioGroupGridGap": "16px",
	"radioLabelPadding": "8px",
	"TEXTAREA_LINE_HEIGHT_SMALL": "20px",
	"TEXTAREA_VERTICAL_PADDING_SMALL": "9px",
	"TEXTAREA_LINE_HEIGHT_MINI": "20px",
	"TEXTAREA_VERTICAL_PADDING_MINI": "8px",
	"FONT_SIZE_HEADING_1": "24px",
	"FONT_SIZE_HEADING_2": "24px",
	"FONT_SIZE_HEADING_3": "24px",
	"FONT_SIZE_BODY": "14px",
	"FONT_SIZE_LABEL": "12px",
	"FONT_SIZE_CODE": "14px",
	"FONT_SIZE_CODE_SMALL": "12px",
	"FONT_SIZE_TAB_SMALL": "14px",
	"FONT_SIZE_TAB_MINI": "12px",
	"LINE_HEIGHT_HEADING_1": "32px",
	"LINE_HEIGHT_HEADING_2": "24px",
	"LINE_HEIGHT_HEADING_3": "16px",
	"LINE_HEIGHT_BODY": "20px",
	"LINE_HEIGHT_LABEL": "16px",
	"LINE_HEIGHT_CODE": "20px",
	"LINE_HEIGHT_CODE_SMALL": "20px",
	"LINE_HEIGHT_TAB_SMALL": "20px",
	"LINE_HEIGHT_TAB_MINI": "20px"
};
module.exports = ___CSS_LOADER_EXPORT___;
