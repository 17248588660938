import Vue from 'vue';
import '@corefy/ui/common';
import '@corefy/ui/styles/common.css';
import '@corefy/ui/styles/components/loading.css'
import UiLoading from '@corefy/ui/directives/ui-loading';
import container from '@corefy/ui/ioc/index';
import { addExtension } from '@corefy/inversify-nuxt';

Vue.use(UiLoading);

  import { UiIcon } from '@corefy/ui/components/ui-icon'
import '@corefy/ui/styles/components/icon.css'
import { UiAlert } from '@corefy/ui/components/ui-alert'
import '@corefy/ui/styles/components/alert.css'
import { UiAlertPopup } from '@corefy/ui/components/ui-alert-popup'
import '@corefy/ui/styles/components/alert-popup.css'
import { UiAvatar } from '@corefy/ui/components/ui-avatar'
import '@corefy/ui/styles/components/avatar.css'
import { UiAvatarGroup } from '@corefy/ui/components/ui-avatar-group'
import '@corefy/ui/styles/components/avatar-group.css'
import { UiButton } from '@corefy/ui/components/ui-button'
import '@corefy/ui/styles/components/button.css'
import { UiButtonGroup } from '@corefy/ui/components/ui-button-group'
import '@corefy/ui/styles/components/button-group.css'
import { UiCard } from '@corefy/ui/components/ui-card'
import '@corefy/ui/styles/components/card.css'
import { UiCardGroup } from '@corefy/ui/components/ui-card-group'
import '@corefy/ui/styles/components/card-group.css'
import { UiCheckbox } from '@corefy/ui/components/ui-checkbox'
import '@corefy/ui/styles/components/checkbox.css'
import { UiControlsGroup } from '@corefy/ui/components/ui-controls-group'
import '@corefy/ui/styles/components/controls-group.css'
import { UiForm } from '@corefy/ui/components/ui-form'
import '@corefy/ui/styles/components/form.css'
import { UiFormControls } from '@corefy/ui/components/ui-form-controls'
import '@corefy/ui/styles/components/form-controls.css'
import { UiFormItem } from '@corefy/ui/components/ui-form-item'
import '@corefy/ui/styles/components/form-item.css'
import { UiIconContainer } from '@corefy/ui/components/ui-icon-container'
import '@corefy/ui/styles/components/icon-container.css'
import { UiImage } from '@corefy/ui/components/ui-image'
import '@corefy/ui/styles/components/image.css'
import { UiInput } from '@corefy/ui/components/ui-input'
import '@corefy/ui/styles/components/input.css'
import { UiLink } from '@corefy/ui/components/ui-link'
import '@corefy/ui/styles/components/link.css'
import { UiMarkdown } from '@corefy/ui/components/ui-markdown'
import '@corefy/ui/styles/components/markdown.css'
import { UiModal } from '@corefy/ui/components/ui-modal'
import '@corefy/ui/styles/components/modal.css'
import { UiMultiselect } from '@corefy/ui/components/ui-multiselect'
import '@corefy/ui/styles/components/multiselect.css'
import { UiPasswordInput } from '@corefy/ui/components/ui-password-input'
import '@corefy/ui/styles/components/password-input.css'
import { UiPopover } from '@corefy/ui/components/ui-popover'
import '@corefy/ui/styles/components/popover.css'
import { UiRadioCard } from '@corefy/ui/components/ui-radio-card'
import '@corefy/ui/styles/components/radio-card.css'
import { UiRadioCardGroup } from '@corefy/ui/components/ui-radio-card-group'
import '@corefy/ui/styles/components/radio-card-group.css'
import { UiRouterLink } from '@corefy/ui/components/ui-router-link'
import '@corefy/ui/styles/components/router-link.css'
import { UiScrollbar } from '@corefy/ui/components/ui-scrollbar'
import '@corefy/ui/styles/components/scrollbar.css'
import { UiSegmentedInput } from '@corefy/ui/components/ui-segmented-input'
import '@corefy/ui/styles/components/segmented-input.css'
import { UiStepper } from '@corefy/ui/components/ui-stepper'
import '@corefy/ui/styles/components/stepper.css'
import { UiTag } from '@corefy/ui/components/ui-tag'
import '@corefy/ui/styles/components/tag.css'
import { UiTextEllipsis } from '@corefy/ui/components/ui-text-ellipsis'
import '@corefy/ui/styles/components/text-ellipsis.css'
import { UiTile } from '@corefy/ui/components/ui-tile'
import '@corefy/ui/styles/components/tile.css'
import { UiTileGroup } from '@corefy/ui/components/ui-tile-group'
import '@corefy/ui/styles/components/tile-group.css'
import { UiTitle } from '@corefy/ui/components/ui-title'
import '@corefy/ui/styles/components/title.css'
import { UiToastPopup } from '@corefy/ui/components/ui-toast-popup'
import '@corefy/ui/styles/components/toast-popup.css'
import { UiTooltip } from '@corefy/ui/components/ui-tooltip'
import '@corefy/ui/styles/components/tooltip.css'
import { UiTextTicker } from '@corefy/ui/components/ui-text-ticker'
import '@corefy/ui/styles/components/text-ticker.css'
import { UiHoverObserver } from '@corefy/ui/components/ui-hover-observer'
import '@corefy/ui/styles/components/hover-observer.css'
import { UiFormItemGroup } from '@corefy/ui/components/ui-form-item-group'
import '@corefy/ui/styles/components/form-item-group.css'
import { UiBaseInput } from '@corefy/ui/components/ui-base-input'
import '@corefy/ui/styles/components/base-input.css'
import { UiMultiselectOption } from '@corefy/ui/components/ui-multiselect-option'
import '@corefy/ui/styles/components/multiselect-option.css'
import { UiMultiselectOptionGroup } from '@corefy/ui/components/ui-multiselect-option-group'
import '@corefy/ui/styles/components/multiselect-option-group.css'
import { UiLollipopList } from '@corefy/ui/components/ui-lollipop-list'
import '@corefy/ui/styles/components/lollipop-list.css'
import { UiLollipop } from '@corefy/ui/components/ui-lollipop'
import '@corefy/ui/styles/components/lollipop.css'
import { UiFocusObserver } from '@corefy/ui/components/ui-focus-observer'
import '@corefy/ui/styles/components/focus-observer.css'

  Vue.component('UiIcon', UiIcon)

Vue.component('UiAlert', UiAlert)

Vue.component('UiAlertPopup', UiAlertPopup)

Vue.component('UiAvatar', UiAvatar)

Vue.component('UiAvatarGroup', UiAvatarGroup)

Vue.component('UiButton', UiButton)

Vue.component('UiButtonGroup', UiButtonGroup)

Vue.component('UiCard', UiCard)

Vue.component('UiCardGroup', UiCardGroup)

Vue.component('UiCheckbox', UiCheckbox)

Vue.component('UiControlsGroup', UiControlsGroup)

Vue.component('UiForm', UiForm)

Vue.component('UiFormControls', UiFormControls)

Vue.component('UiFormItem', UiFormItem)

Vue.component('UiIconContainer', UiIconContainer)

Vue.component('UiImage', UiImage)

Vue.component('UiInput', UiInput)

Vue.component('UiLink', UiLink)

Vue.component('UiMarkdown', UiMarkdown)

Vue.component('UiModal', UiModal)

Vue.component('UiMultiselect', UiMultiselect)

Vue.component('UiPasswordInput', UiPasswordInput)

Vue.component('UiPopover', UiPopover)

Vue.component('UiRadioCard', UiRadioCard)

Vue.component('UiRadioCardGroup', UiRadioCardGroup)

Vue.component('UiRouterLink', UiRouterLink)

Vue.component('UiScrollbar', UiScrollbar)

Vue.component('UiSegmentedInput', UiSegmentedInput)

Vue.component('UiStepper', UiStepper)

Vue.component('UiTag', UiTag)

Vue.component('UiTextEllipsis', UiTextEllipsis)

Vue.component('UiTile', UiTile)

Vue.component('UiTileGroup', UiTileGroup)

Vue.component('UiTitle', UiTitle)

Vue.component('UiToastPopup', UiToastPopup)

Vue.component('UiTooltip', UiTooltip)

Vue.component('UiTextTicker', UiTextTicker)

Vue.component('UiHoverObserver', UiHoverObserver)

Vue.component('UiFormItemGroup', UiFormItemGroup)

Vue.component('UiBaseInput', UiBaseInput)

Vue.component('UiMultiselectOption', UiMultiselectOption)

Vue.component('UiMultiselectOptionGroup', UiMultiselectOptionGroup)

Vue.component('UiLollipopList', UiLollipopList)

Vue.component('UiLollipop', UiLollipop)

Vue.component('UiFocusObserver', UiFocusObserver)

let initRoutes = []

export default context => {
  context.initRoutes = initRoutes
  addExtension(context, container);
};