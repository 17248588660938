export const iocTypes = {
  // Error handling
  ErrorProcessor: Symbol.for('ErrorProcessor'),
  ErrorReporter: Symbol.for('ErrorReporter'),
  ErrorAlertService: Symbol.for('ErrorAlertService'),
  Sentry: Symbol.for('Sentry'),

  // JSON API
  RelationsMapperFactory: Symbol.for('RelationsMapperFactory'),

  // Communication
  HttpClientFactory: Symbol.for('HttpClientFactory'),

  // Localisation
  LocalizationService: Symbol.for('LocalizationService'),

  // Store
  Store: Symbol.for('Store'),
  RegistrationStore: Symbol.for('RegistrationStore'),
  ResettingPassStore: Symbol.for('ResettingPassStore'),
  OrganizationStore: Symbol.for('OrganizationStore'),
  UniversalStorageServiceFactory: Symbol.for('UniversalStorageServiceFactory'),
  UserStore: Symbol.for('UserStore'),
  BlogStore: Symbol.for('BlogStore'),

  // Indicators
  IndicatorsService: Symbol.for('IndicatorsService'),

  // Router
  RoutingNavigator: Symbol.for('RoutingNavigator'),
  Router: Symbol.for('Router'),
  LocationManager: Symbol.for('LocationManager'),

  // Validation
  AsyncValidatorBuilderFactory: Symbol.for('AsyncValidatorBuilderFactory'),
  AsyncFormValidationControllerFactory: Symbol.for('AsyncFormValidationControllerFactory'),

  // Notification
  NotificationServiceFactory: Symbol.for('NotificationServiceFactory'),

  // Form Changes
  FormChangesServiceFactory: Symbol.for('FormChangesServiceFactory'),

  // Tab events
  TabEventEmitter: Symbol.for('TabEventEmitter'),

  // Facade
  SystemHttpClient: Symbol.for('SystemHttpClient'),
  UserHttpClient: Symbol.for('UserHttpClient'),
  OrganizationHttpClient: Symbol.for('OrganizationHttpClient'),
  MemberHttpClient: Symbol.for('MemberHttpClient'),
  BlogHttpClient: Symbol.for('BlogHttpClient'),

  // Auth
  AuthService: Symbol.for('AuthService'),
  AuthRedirectService: Symbol.for('AuthRedirectService'),
  LoginStore: Symbol.for('LoginStore'),

  // App data
  AppDataStore: Symbol.for('AppDataStore'),

  // Cookie
  CookieService: Symbol.for('CookieService'),

  // Alert
  AlertService: Symbol.for('AlertService'),

  // Portals
  PortalsStore: Symbol.for('PortalsStore'),

  // Map
  CountriesMap: Symbol.for('CountriesMap'),
  CurrenciesMap: Symbol.for('CurrenciesMap'),

  // Formatting
  DateFormatterFactory: Symbol.for('DateFormatterFactory'),
};
