// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-margin{margin:0!important}.reset-padding{padding:0!important}.loggia{height:100vh;min-height:640px;width:100%;min-width:768px;display:flex;flex-direction:column;justify-content:flex-start;align-items:center}.loggia .loggia__logo{padding:32px 0;margin-bottom:48px;text-align:center}.loggia.loggia--default .loggia__content{flex:1}.loggia.loggia--mono .loggia__content{display:flex;flex:1;flex-direction:column;box-sizing:border-box}.loggia.loggia--mono .loggia__footer{width:-webkit-fill-available;width:-moz-available;width:stretch;padding:32px 142px;background-color:#fff;box-sizing:border-box}.loggia.loggia--mono .loggia__footer--sticky{position:-webkit-sticky;position:sticky;bottom:0}.loggia.loggia--mono .loggia__footer>*{width:100%}.loggia.loggia--split{display:flex;flex-direction:row;align-items:center;justify-content:flex-start}.loggia.loggia--split .loggia__left-side{width:50%;height:100%;padding:0 24px;box-sizing:border-box}@media(max-width:1024px){.loggia.loggia--split .loggia__left-side{width:100%}}.loggia.loggia--split .loggia__left-side-content{height:100%;display:flex;flex-direction:column}.loggia.loggia--split .loggia__left-side .loggia__content,.loggia.loggia--split .loggia__left-side .loggia__footer{box-sizing:border-box;width:100%;max-width:458px;margin:0 auto;background-color:#fff}.loggia.loggia--split .loggia__left-side .loggia__header{padding:0 5px}.loggia.loggia--split .loggia__left-side .loggia__content{flex:1}.loggia.loggia--split .loggia__left-side .loggia__content--main{padding:0 5px}.loggia.loggia--split .loggia__left-side .loggia__footer{padding:32px 5px}.loggia.loggia--split .loggia__right-side{width:50%;overflow:hidden;box-sizing:border-box;height:100%;background-color:#f7f9fa}@media(max-width:1024px){.loggia.loggia--split .loggia__right-side{display:none}}.footer{width:100%;display:flex;align-items:center;justify-content:space-between}.fw--medium{font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ALERT_POPUP_WIDTH": "360px",
	"ALERT_POPUP_POSITION_LEFT_OFFSET": "200px",
	"ALERT_POPUP_POSITION_RIGHT_OFFSET": "200px",
	"CHECKBOX_GROUP_GRID_GAP": "16px",
	"CHECKBOX_LABEL_PADDING": "8px",
	"CODE_INPUT_LINE_HEIGHT_SMALL": "20px",
	"CODE_INPUT_VERTICAL_PADDING_SMALL": "9px",
	"CODE_INPUT_LINE_HEIGHT_MINI": "20px",
	"CODE_INPUT_VERTICAL_PADDING_MINI": "8px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_SIZE": "488px",
	"IMAGE_UPLOADER_CROPPER_OVERLAY_OFFSET": "24px",
	"IMAGE_UPLOADER_SLIDER_HANDLE_SIZE": "16px",
	"LAYOUT_FOOTER_HEIGHT": "64px",
	"MENU_ITEM_CARET_HEIGHT": "24px",
	"MENU_ITEM_HEIGHT": "32px",
	"radioGroupGridGap": "16px",
	"radioLabelPadding": "8px",
	"TEXTAREA_LINE_HEIGHT_SMALL": "20px",
	"TEXTAREA_VERTICAL_PADDING_SMALL": "9px",
	"TEXTAREA_LINE_HEIGHT_MINI": "20px",
	"TEXTAREA_VERTICAL_PADDING_MINI": "8px",
	"FONT_SIZE_HEADING_1": "24px",
	"FONT_SIZE_HEADING_2": "24px",
	"FONT_SIZE_HEADING_3": "24px",
	"FONT_SIZE_BODY": "14px",
	"FONT_SIZE_LABEL": "12px",
	"FONT_SIZE_CODE": "14px",
	"FONT_SIZE_CODE_SMALL": "12px",
	"FONT_SIZE_TAB_SMALL": "14px",
	"FONT_SIZE_TAB_MINI": "12px",
	"LINE_HEIGHT_HEADING_1": "32px",
	"LINE_HEIGHT_HEADING_2": "24px",
	"LINE_HEIGHT_HEADING_3": "16px",
	"LINE_HEIGHT_BODY": "20px",
	"LINE_HEIGHT_LABEL": "16px",
	"LINE_HEIGHT_CODE": "20px",
	"LINE_HEIGHT_CODE_SMALL": "20px",
	"LINE_HEIGHT_TAB_SMALL": "20px",
	"LINE_HEIGHT_TAB_MINI": "20px"
};
module.exports = ___CSS_LOADER_EXPORT___;
