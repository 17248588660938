

















































































































import { Component, Vue, Watch } from 'nuxt-property-decorator';
import { portal } from '@/shared/portals/enum/portal';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { PortalsStore } from '@/shared/portals/Portals';
import { Route } from 'vue-router';
import { Modal } from '@/shared/global-modals/enumerations/modals';
import { modalsAssets } from '@/shared/global-modals/assets/modalsAssets';
import type { VueConstructor } from 'vue';
import { omit } from 'lodash';
import AppLogo from '@/shared/ui/AppLogo.vue';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { getFaviconHeaderLinks } from '@/shared/utils/getFaviconHeaderLinks';

@Component({
  components: {
    AppLogo,
  },
})
export default class LoggiaSplitLayout extends Vue {
  $refs!: {
    pointsWrap: HTMLElement;
  };

  @inject(iocTypes.PortalsStore)
  private readonly portalsStore!: PortalsStore;

  @inject(iocTypes.AppDataStore)
  appData!: AppDataStore;

  head() {
    return {
      link: getFaviconHeaderLinks(this.appData),
    };
  }

  mounted() {
    this.checkRouteAssets();
    this.isMounted = true;
  }

  private showModal = false;
  private modalComponent: VueConstructor | null = null;
  private merchantPoints = {
    payments: {
      icon: {
        name: 'arrow-down-to-bracket',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_payments_point__title',
      subTitle: 'loggia__org_creation__type__merchant_payments_point__subtitle',
    },
    payouts: {
      icon: {
        name: 'up-from-bracket',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_payouts_point__title',
      subTitle: 'loggia__org_creation__type__merchant_payouts_point__subtitle',
    },
    integrations: {
      icon: {
        name: 'plug',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_integration_point__title',
      subTitle: 'loggia__org_creation__type__merchant_integration_point__subtitle',
    },
    dashboard: {
      icon: {
        name: 'objects-column',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_dashboard_point__title',
      subTitle: 'loggia__org_creation__type__merchant_dashboard_point__subtitle',
    },
  };
  private institutionPoints = {
    portal: {
      icon: {
        name: 'table-columns',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_portal_point__title',
      subTitle: 'loggia__org_creation__type__merchant_portal_point__subtitle',
    },
    ledger: {
      icon: {
        name: 'chart-network',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_ledger_point__title',
      subTitle: 'loggia__org_creation__type__merchant_ledger_point__subtitle',
    },
    firewall: {
      icon: {
        name: 'shield',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_firewall_point__title',
      subTitle: 'loggia__org_creation__type__merchant_firewall_point__subtitle',
    },
    reports: {
      icon: {
        name: 'file-lines',
        type: 'solid',
      },
      title: 'loggia__org_creation__type__merchant_reports_point__title',
      subTitle: 'loggia__org_creation__type__merchant_reports_point__subtitle',
    },
  };

  private portalTarget = portal;
  private isMounted = false;
  private rightSideView: 'stub' | 'merchant' | 'payment_institution' = 'stub';
  private rightSideViews = {
    stub: {
      transform: 'translate(-25%, 0)',
    },
    merchant: {
      transform: 'translate(0, -50%)',
    },
    payment_institution: {
      transform: 'translate(-50%, -50%)',
    },
  };

  @Watch('isMounted')
  private mountedHandler(isMounted: boolean) {
    if (isMounted) this.addTransitionOnWrap();
  }

  @Watch('$route')
  private routeHandler(route: Route) {
    const { hash } = route;
    if (hash && hash === '#merchant') {
      this.rightSideView = 'merchant';
    } else if (hash && hash === '#payment_institution') {
      this.rightSideView = 'payment_institution';
    } else {
      this.rightSideView = 'stub';
    }
  }

  private get headerPassed() {
    return this.portalsStore.getItem('header');
  }

  private get wrapView() {
    return this.rightSideViews[this.rightSideView];
  }

  private addTransitionOnWrap() {
    this.$nextTick(() => {
      this.$refs.pointsWrap.style.transition = 'transform .7s ease-out';
    });
  }

  private clearUrlQuery() {
    this.$router.replace({ query: omit(this.$route.query, ['modal']) });
  }

  private checkModals(modal: Modal) {
    const modalAsset = modalsAssets.getFor(modal);
    if (modalAsset) {
      this.modalComponent = modalAsset.component;
      this.showModal = true;
      this.clearUrlQuery();
    } else {
      console.error(`Modal ${modal} is not presented in modalAssets`);
    }
  }

  private checkRouteAssets() {
    const { modal } = this.$route.query;
    if (modal) {
      this.checkModals(modal as Modal);
    }
  }
}
