// If for some reason you change the standard, be sure to update the document.
/**
 * Codes of locales supported by at least one HPP.
 * Used standard is ISO 639-1.
 */
const AppLocale = {
  English: 'en' as const,
  Ukrainian: 'uk' as const,
};

type AppLocale = typeof AppLocale[keyof typeof AppLocale];

export { AppLocale };
