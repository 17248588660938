import { interfaces } from 'inversify';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { SuppressError } from '@corefy/indicators/utils/SuppressError';
import { Store } from 'vuex';
import { getModule, Module, VuexAction, VuexModule } from 'nuxt-property-decorator';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { ResettingPassStore } from '@/shared/resetting-password/ResettingPassStore';

export const createResettingPassStore = (ctx: interfaces.Context) => {
  const NAMESPACE = 'RestoreStore';

  const indicators = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);

  const bindingKeys = {
    resettingPassIndicators: `${NAMESPACE}/resettingPassIndicators`,
    resettingPassConfirmIndicators: `${NAMESPACE}/resettingPassConfirmIndicators`,
    passResetTokenValidationIndicators: `${NAMESPACE}/passResetTokenValidationIndicators`,
  };

  const systemHttpClient = ctx.container.get<SystemHttpClient>(iocTypes.SystemHttpClient);
  const store = ctx.container.get<Store<any>>(iocTypes.Store);

  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class DefaultResettingPassStore extends VuexModule implements ResettingPassStore {
    get resettingPassIndicators() {
      return indicators.getIndicators(bindingKeys.resettingPassIndicators);
    }

    get resettingPassConfirmIndicators() {
      return indicators.getIndicators(bindingKeys.resettingPassConfirmIndicators);
    }

    get passResetTokenValidationIndicators() {
      return indicators.getIndicators(bindingKeys.passResetTokenValidationIndicators);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.resettingPassIndicators)
    async resettingPass(payload: { email: string }): Promise<void> {
      await systemHttpClient.resetPassword(payload);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.resettingPassConfirmIndicators)
    async resettingPassConfirm(payload: { password: string; token: string }) {
      await systemHttpClient.confirmPaswordResetting(payload);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.passResetTokenValidationIndicators)
    async passResetTokenValidation(token: string) {
      const resp = await systemHttpClient.passResetTokenValidation(token);
    }
  }

  return getModule(DefaultResettingPassStore);
};
