import { inject, injectable } from 'inversify';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { HttpClient, HttpClientFactory } from '@corefy/http/services/HttpClient';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import {
  LoginRequestPayload,
  LoginResponsePayload,
  PasswordChangeRequestPayload,
  PasswordChangeResponsePayload,
  RegistrationConfirmationRequestPayload,
  RegistrationConfirmationResponsePayload,
  RegistrationRequestPayload,
  RegistrationResponsePayload,
  ResettingPasswordRequestPayload,
  ResettingPasswordResponsePayload,
  ResettingRequestPayload,
  ResettingResponsePayload,
} from '@/shared/facade/interfaces/entity/system';
import { getBaseFacadeClientConfig } from '@/shared/facade/consts/facadeClientConfig';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

@injectable()
export class DefaultSystemHttpClient implements SystemHttpClient {
  constructor(
    @inject(iocTypes.HttpClientFactory) createHttpClient: HttpClientFactory,
    @inject(iocTypes.AppDataStore) appDataStore: AppDataStore
  ) {
    const { FACADE_URL } = appDataStore.data.env;
    this._httpClient = createHttpClient(getBaseFacadeClientConfig(FACADE_URL));
  }

  login(payload: LoginRequestPayload) {
    return this._httpClient.post<LoginResponsePayload>('/check-login', payload);
  }

  changePassword(payload: PasswordChangeRequestPayload) {
    return this._httpClient.post<PasswordChangeResponsePayload>('/password/change', payload);
  }

  register(payload: RegistrationRequestPayload) {
    return this._httpClient.post<RegistrationResponsePayload>('/registration', payload);
  }

  confirmRegistration(payload: RegistrationConfirmationRequestPayload) {
    return this._httpClient.post<RegistrationConfirmationResponsePayload>(
      '/registration/confirmation',
      payload
    );
  }

  confirmRegistrationResend(payload: { email: string }) {
    return this._httpClient.post<{ data: string }>('/registration/confirmation/resend', payload);
  }

  resetPassword(payload: ResettingRequestPayload) {
    return this._httpClient.post<ResettingResponsePayload>('/resetting', payload);
  }

  confirmPaswordResetting(payload: ResettingPasswordRequestPayload) {
    return this._httpClient.post<ResettingPasswordResponsePayload>('/resetting/password', payload);
  }

  passResetTokenValidation(token: string) {
    return this._httpClient.post<ResettingPasswordResponsePayload>(
      `/resetting/password/validate?token=${token}`
    );
  }

  getMemberInviteByToken(token: string) {
    return this._httpClient.get(`/member-invites/token/${token}`);
  }

  check2Fa(payload: { auth_code: string }): Promise<any> {
    return this._httpClient.post(`2fa-check`, payload);
  }

  getEnumeration(params: { name: string }) {
    return this._httpClient.get(`/system/enumerations`, { params });
  }

  logout() {
    return this._httpClient.get<any>('/logout');
  }

  private _httpClient: HttpClient;
}
