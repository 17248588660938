import { ExtendedRouteConfig } from '@/shared/router/interfaces/ExtendedRouterConfig';
import { RouteType } from '@/router/enum/RouteType';
import { LOGIN_ROUTE_PATH, ORG_LIST_ROUTE_PATH } from "@/router/consts/route-names";

/* ================================= Entrance routes ================================= */
const Login = () => import('@/modules/entrance/pages/login/Index.vue').then((m) => m.default || m);

const Registration = () =>
  import('@/modules/entrance/pages/registration/Index.vue').then((m) => m.default || m);

const RegistrationResendLink = () =>
  import('@/modules/entrance/pages/registration-resend-link/Index.vue').then((m) => m.default || m);

const TwoFactor = () =>
  import('@/modules/entrance/pages/2fa/Index.vue').then((m) => m.default || m);

const TwoFactorRecovery = () =>
  import('@/modules/entrance/pages/2fa-recovery/Index.vue').then((m) => m.default || m);

const ResetPass = () =>
  import('@/modules/entrance/pages/reset-password/Index.vue').then((m) => m.default || m);

const ResendLink = () =>
  import('@/modules/entrance/pages/resend-recovery-link/Index.vue').then((m) => m.default || m);

const ResetPassConfirm = () =>
  import('@/modules/entrance/pages/reset-password-confirm/Index.vue').then((m) => m.default || m);

const JoinStub = () =>
  import('@/modules/entrance/pages/join-stub/Index.vue').then((m) => m.default || m);

const RegistrationConfirmationStub = () =>
  import('@/modules/entrance/pages/registration-confirmation-stub/Index.vue').then(
    (m) => m.default || m
  );

/* ================================= Loggia routes ================================= */
const OrgList = () =>
  import('@/modules/loggia/pages/organisations-list/Index.vue').then((m) => m.default || m);

const TwoAuthSetup = () =>
  import('@/modules/loggia/pages/2fa-setup/Index.vue').then((m) => m.default || m);

const OrganizationCreate = () =>
  import('@/modules/loggia/pages/organization-create/Index.vue').then((m) => m.default || m);

const PrepareOrganization = () =>
  import('@/modules/loggia/pages/organization-create/children/PrepareOrganization.vue').then(
    (m) => m.default || m
  );

const routes: ExtendedRouteConfig[] = [
  /* ============= Entrance routes ============= */
  {
    path: '/join/:token',
    name: 'join',
    component: JoinStub,
    meta: {
      type: RouteType.Unauthorized,
    },
  },
  {
    path: LOGIN_ROUTE_PATH,
    name: 'login',
    component: Login,
    meta: {
      type: RouteType.Unauthorized,
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    meta: {
      type: RouteType.Unauthorized,
    },
    children: [
      {
        path: 'resend-link',
        name: 'registration-resend-link',
        component: RegistrationResendLink,
      },
    ],
  },
  {
    path: '/registration/confirmation',
    name: 'registration-confirmation',
    component: RegistrationConfirmationStub,
    meta: {
      type: RouteType.Unauthorized,
    },
  },
  {
    path: '/2fa',
    name: '2fa',
    component: TwoFactor,
    meta: {
      type: RouteType.Unauthorized,
    },
    children: [
      {
        path: 'recovery',
        name: '2fa-recovery',
        component: TwoFactorRecovery,
        meta: {
          type: RouteType.Unauthorized,
        },
      },
    ],
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: ResetPass,
    meta: {
      type: RouteType.Unauthorized,
    },
    children: [
      {
        path: 'resend-link',
        name: 'resend-link',
        component: ResendLink,
      },
    ],
  },
  {
    path: '/resetting/password',
    name: 'reset-password',
    component: ResetPassConfirm,
    meta: {
      type: RouteType.Unauthorized,
    },
  },
  /* ============= Loggia routes ============= */
  {
    path: ORG_LIST_ROUTE_PATH,
    name: 'org-list',
    component: OrgList,
    meta: {
      type: RouteType.Authorized,
    },
  },
  {
    path: '/setup-2fa',
    name: 'setup-2fa',
    component: TwoAuthSetup,
    meta: {
      type: RouteType.Authorized,
    },
  },

  {
    path: '/create-organization',
    name: 'create-organization',
    component: OrganizationCreate,
    meta: {
      type: RouteType.Authorized,
    },
    children: [
      {
        path: 'prepare',
        name: 'prepare-organization',
        component: PrepareOrganization,
      },
    ],
  },
];

export default routes;
