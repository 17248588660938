








































import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { UserStore } from '@/shared/user/store/UserStore';
import { ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';

const CREATE_ORG_ROUTE = 'create-organization';

@Component
export default class OrganizationNotFound extends Vue {
  @inject(iocTypes.UserStore)
  private readonly userStore!: UserStore;

  @inject(iocTypes.ErrorAlertService)
  private readonly errorAlertService!: ErrorAlertService;

  @Prop({ type: Boolean, default: false })
  private readonly value!: boolean;

  mounted() {
    this.visible = this.model;
  }

  private visible = false;

  private set model(value: boolean) {
    this.$emit('input', value);
  }

  private get hasNoAvailableOrgs() {
    return this.$route.name === CREATE_ORG_ROUTE;
  }

  private get logoutIndicators() {
    return this.userStore.logoutIndicators;
  }

  private get model() {
    return this.value;
  }

  private async closedHandler() {
    this.model = false;
  }

  private async changeAccountHandler() {
    await this.userStore.logout();

    if (this.logoutIndicators.error) {
      this.errorAlertService.showError({ error: this.logoutIndicators.error });
    }
  }

  private changeOrgHandler() {
    this.model = false;
  }
}
