import { Plugin } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AlertService } from '@corefy/ui/services/alert/AlertService';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { CookieService } from '@/shared/cookie/services/CookieService';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { RouteType } from '@/router/enum/RouteType';

const plugin: Plugin = async (ctx) => {
  const cookieService = ctx.$container.get<CookieService>(iocTypes.CookieService);
  const appDataStore = ctx.$container.get<AppDataStore>(iocTypes.AppDataStore);

  const loggedIn = cookieService.getCookie('loggedIn');
  const unauthorizedRouteType = ctx.route.meta?.type === RouteType.Unauthorized;

  if (loggedIn && unauthorizedRouteType) {
    cookieService.removeCookie('loggedIn', { domain: appDataStore.data.env.COOKIE_DOMAIN });
    const alert = ctx.$container.get<AlertService>(iocTypes.AlertService);
    const localizationService = ctx.$container.get<LocalizationService>(
      iocTypes.LocalizationService
    );

    alert.showAlert({
      disableClose: false,
      color: 'danger',
      title: localizationService.t('error__session_expired') as string,
      icon: {
        name: 'diamond-exclamation',
        type: 'solid',
      },
    });
  }
};
export default plugin;
