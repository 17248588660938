








import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { portal } from '@/shared/portals/enum/portal';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { PortalKeys, PortalsStore } from '@/shared/portals/Portals';

@Component
export default class PortalSource extends Vue {
  @inject(iocTypes.PortalsStore)
  private readonly portalsStore!: PortalsStore;

  @Prop({
    type: String,
    required: true,
    validator: (value: any) => Object.values(portal).includes(value),
  })
  private readonly to!: PortalKeys;

  mounted() {
    this.portalsStore.setItem(this.to, true);
  }

  updated() {
    this.portalsStore.setItem(this.to, true);
  }

  /* We use id because if we pass a component to the portal,
   * we will receive the same instance of this component on different pages with data
   */
  private getPortalUID() {
    //@ts-ignore
    return this._uid;
  }
}
