import { interfaces } from 'inversify';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { SuppressError } from '@corefy/indicators/utils/SuppressError';
import { Store } from 'vuex';
import { getModule, Module, VuexAction, VuexModule } from 'nuxt-property-decorator';
import { statePresent } from '@corefy/vuex/utils/statePresent';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { RegisterPayload, RegistrationStore } from '@/shared/registration/RegistrationStore';

export const createRegistrationStore = (ctx: interfaces.Context) => {
  const NAMESPACE = 'RegistrationStore';

  const indicators = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);

  const bindingKeys = {
    registerIndicators: `${NAMESPACE}/registerIndicators`,
    registrationConfirmation: `${NAMESPACE}/registrationConfirmation`,
    registrationConfirmationResend: `${NAMESPACE}/registrationConfirmationResend`,
  };

  const systemHttpClient = ctx.container.get<SystemHttpClient>(iocTypes.SystemHttpClient);
  const store = ctx.container.get<Store<any>>(iocTypes.Store);

  @Module({
    namespaced: true,
    name: NAMESPACE,
    dynamic: true,
    store,
    preserveState: process.client && statePresent(NAMESPACE, store),
  })
  class DefaultRegistrationStore extends VuexModule implements RegistrationStore {
    get registerIndicators() {
      return indicators.getIndicators(bindingKeys.registerIndicators);
    }

    get registrationConfirmationIndicators() {
      return indicators.getIndicators(bindingKeys.registrationConfirmation);
    }

    get registrationConfirmationResendIndicators() {
      return indicators.getIndicators(bindingKeys.registrationConfirmationResend);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.registrationConfirmation)
    async registrationConfirmation(payload: { token: string }) {
      await systemHttpClient.confirmRegistration(payload);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.registrationConfirmationResend)
    async registrationConfirmationResend(payload: { email: string }) {
      const resp = await systemHttpClient.confirmRegistrationResend(payload);
    }

    @VuexAction
    @SuppressError()
    @indicators.Decorate(bindingKeys.registerIndicators)
    async register(payload: RegisterPayload) {
      await systemHttpClient.register(payload);
    }
  }

  return getModule(DefaultRegistrationStore);
};
