import { Middleware } from '@nuxt/types';
import { join } from '@/shared/utils/joinResolver';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { iocTypes } from '@/shared/ioc/iocTypes';

const middleware: Middleware = async (ctx) => {
  const appData = ctx.$container.get<AppDataStore>(iocTypes.AppDataStore);

  if (appData.data.env.REGISTRATION_DISABLED === 'true') {
    return;
  }

  await join(ctx);
};

export default middleware;
