












































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { BlogStore } from '@/shared/blog/store/BlogStore';
import { LocalizationService } from '@corefy/localization/services/LocalizationService';
import { Position, scrollPosition } from '@corefy/ui/components/ui-scrollbar';

@Component
export default class EntranceBlog extends Vue {
  @inject(iocTypes.BlogStore)
  private blogStore!: BlogStore;

  @inject(iocTypes.LocalizationService)
  private readonly i18n!: LocalizationService;

  @Watch('scrollPosition')
  private scrollPositionWatcher(position: Position) {
    if (position === scrollPosition.end) {
      this.lazyLoad()
    }
  }

  @Prop({ type: String })
  readonly scrollPosition!: string;

  private tags = {
    articles: 'article-item__tags-articles',
    updates: 'article-item__tags-updates',
    announcements: 'article-item__tags-announcements',
    integrations: 'article-item__tags-integrations',
    events: 'article-item__tags-events',
    cases: 'article-item__tags-cases',
  }

  private get blogArticles() {
    return this.blogStore.posts
  }

  private get pageNumber() {
    return this.blogStore.pageNumber
  }

  private lazyLoad() {
    this.blogStore.getPosts(this.pageNumber!)
  }
}
