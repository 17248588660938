import { Context } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { LocationManager } from '@/shared/router/services/LocationManager';
import { ClientLocationManager } from '@/shared/router/services/implementation/ClientLocationManager';
import { Container } from 'inversify';

export default (nuxtCtx: Context, container: Container) => {
  // ====================================== Location Manager ==========================
  container
    .bind<LocationManager>(iocTypes.LocationManager)
    .to(ClientLocationManager)
    .inSingletonScope();

  return container;
};
