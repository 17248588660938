import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

export const getFaviconHeaderLinks = (appData: AppDataStore) => {
  const { FAVICON_16_URL, FAVICON_32_URL, FAVICON_180_URL } = appData.data.env;
  return [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: FAVICON_180_URL,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: FAVICON_32_URL,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: FAVICON_16_URL,
    },
  ];
};
