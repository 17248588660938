import { Context } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { Alert } from '@/shared/global-alerts/enumerations/alerts';
import { AuthService } from '@/shared/auth/services/AuthService';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import { dateToUnix } from '@corefy/common/utils/cast/dateToUnix';

const ORGANIZATIONS_LIST_ROUTE = 'org-list';
const LOGIN_ROUTE = 'login';
const REGISTRATION_ROUTE = 'registration';
const INVITE_NOT_FOUND_REVOKED = 'Request failed with status code 404';

const getError = (token: string) => {
  return token ? Alert.SomethingWentWrong : Alert.IncorrectLink;
};

export const join = async (ctx: Context) => {
  const { $container, route, redirect } = ctx;
  const { token } = route.params;

  if (!token) {
    redirect({
      name: LOGIN_ROUTE,
      query: {
        alert: getError(token as string),
      },
    });
  }

  const systemHttp = $container.get<SystemHttpClient>(iocTypes.SystemHttpClient);
  const authService = $container.get<AuthService>(iocTypes.AuthService);
  const { authorized } = await authService.checkAuthorization(ctx);

  try {
    const resp = await systemHttp.getMemberInviteByToken(token);
    const {
      user_exists: registered,
      user_login_under_another_email: loginUnderAnotherUser,
      email,
      inviter_name: inviterName,
      organization_name: organizationName,
      expires,
    } = resp.data.data.attributes;

    if (expires < dateToUnix(new Date())) {
      redirect({
        name: authorized ? ORGANIZATIONS_LIST_ROUTE : LOGIN_ROUTE,
        query: {
          alert: Alert.InviteHasExpired,
        },
      });
    }

    if (!authorized && !registered && !loginUnderAnotherUser) {
      redirect({
        name: REGISTRATION_ROUTE,
        query: {
          email,
          inviterName,
          organizationName,
          token,
        },
      });
    } else if (!authorized && registered && !loginUnderAnotherUser) {
      redirect({
        name: LOGIN_ROUTE,
        query: {
          email,
        },
      });
    } else if (authorized) {
      redirect({
        name: ORGANIZATIONS_LIST_ROUTE,
        ...(loginUnderAnotherUser
          ? {
              query: {
                modal: 'signed_under_another_acc',
                token,
              },
            }
          : {}),
      });
    }
  } catch (error: any) {
    if (error?.message === INVITE_NOT_FOUND_REVOKED) {
      redirect({
        name: LOGIN_ROUTE,
        query: {
          alert: Alert.InviteNotFoundOrRevoked,
        },
      });
    } else {
      redirect({
        name: LOGIN_ROUTE,
        query: {
          alert: getError(token as string),
        },
      });
    }
  }
};
