import { Container } from 'inversify';
import { Context } from '@nuxt/types';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { ErrorProcessor } from '@corefy/error/services/ErrorProcessor';
import { createErrorProcessorFactory } from '@corefy/error/services/implementations/DefaultErrorProcessor';
import { createEmptyValueValidatorFactory } from '@corefy/validation/services/implementations/EmptyValueValidator';
import { createRequiredValidatorFactory } from '@corefy/validation/services/implementations/RequiredValidator';
import { createStringValidatorFactory } from '@corefy/validation/services/implementations/StringValidator';
import { createRegexpValidatorFactory } from '@corefy/validation/services/implementations/RegexpValidator';
import { AppLocale } from '@/shared/localization/enumerations/AppLocale';
import { RelationsMapperFactory } from '@corefy/json-api/services/RelationsMapper';
import { createRelationMapperFactory } from '@corefy/json-api/services/implementations/DefaultRelationsMapper';
import { HttpClientConfig, HttpClientFactory } from '@corefy/http/services/HttpClient';
import { createNuxtAxiosHttpClientFactory } from '@corefy/http-axios-nuxt/services/implementations/NuxtAxiosHttpClient';
import type { Store } from 'vuex';
import { createTreeNavigatorFactory } from '@corefy/tree-navigation/services/implementations/DefaultTreeNavigator';
import { DefaultSystemHttpClient } from '@/shared/facade/services/implementation/DefaultSystemHttpClient';
import { SystemHttpClient } from '@/shared/facade/services/SystemHttpClient';
import { AuthService } from '@/shared/auth/services/AuthService';
import { DefaultAuthService } from '@/shared/auth/services/implementation/DefaultAuthService';
import type Router from 'vue-router';
import { UserStore } from '@/shared/user/store/UserStore';
import { createUserStoreModule } from '@/shared/user/store/implementation/UserStore';
import { AuthRedirectService } from '@/shared/auth/services/AuthRedirectService';
import { DefaultAuthRedirectService } from '@/shared/auth/services/implementation/DefaultAuthRedirectService';
import { createAppDataStore } from '@/shared/app-data/store/implementation/DefaultAppDataStore';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { RoutingNavigator } from '@/shared/router/services/RoutingNavigator';
import { flatQuery } from '@corefy/http/utils/flatQuery';
import { FormChangesServiceFactory } from '@corefy/form-changes/services/FormChangesService';
import { createFormChangesServiceFactory } from '@corefy/form-changes/services/implementations/DefaultFormChangesService';
import { createPhoneValidatorFactory } from '@/shared/validation/services/PhoneValidator';
import { createEmailValidatorFactory } from '@/shared/validation/services/EmailValidator';
import { LocalizationService } from '@corefy/localization/services/LocalizationService';
import { NotificationServiceFactory } from '@/shared/notification/NotificationService';
import { createNotificationServiceFactory } from '@/shared/notification/implementation/NotificationService';
import { nuxtI18nLocalisationServiceFactory } from '@corefy/localization-i18n-nuxt/services/implementations/Nuxt18nLocalizationService';
import { IndicatorsService } from '@corefy/indicators/services/IndicatorsService';
import { createIndicatorsServiceFactory } from '@corefy/indicators/services/implementations/DefaultIndicatorsService';
import { createVuexIndicatorsStoreModule } from '@corefy/indicators-vuex/store/modules/VuexIndicatorsStore';
import { RegistrationStore } from '@/shared/registration/RegistrationStore';
import { createRegistrationStore } from '@/shared/registration/implementation/DefaultRegistrationStore';
import { ResettingPassStore } from '@/shared/resetting-password/ResettingPassStore';
import { createResettingPassStore } from '@/shared/resetting-password/implementation/DefaultResettingPassStore';
import { DefaultUserHttpClient } from '@/shared/facade/services/implementation/DefaultUserHttpClient';
import { UserHttpClient } from '@/shared/facade/services/UserHttpClient';
import { OrganizationStore } from '@/shared/organization/store/OrganizationStore';
import { createOrganizationStoreModule } from '@/shared/organization/store/implementation/OrganizationStore';
import { OrganizationHttpClient } from '@/shared/facade/services/OrganizationHttpClient';
import { DefaultOrganizationHttpClient } from '@/shared/facade/services/implementation/DefaultOrganizationHttpClient';
import { nuxtCookieServiceFactory } from '@corefy/cookie-nuxt/services/implementations/NuxtCookieService';
import { CookieService } from '@/shared/cookie/services/CookieService';
import { DefaultErrorsHandle } from '@/shared/errors/implementation/DefaultErrorsHandle';
import { DefaultHttpErrorsHandler } from '@/shared/errors/implementation/DefaultHttpErrorsHadler';
import { ErrorReporter } from '@corefy/error/services/ErrorReporter';
import { DefaultErrorsReporter } from '@/shared/errors/implementation/DefaultErrorsReporter';
import { DefaultErrorAlertService } from '@/shared/errors/implementation/DefaultErrorAlertService';
import { ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';
import { Portals, PortalsStore } from '@/shared/portals/Portals';
import { UniversalStorageServiceFactory } from '@corefy/universal-storage/services/UniversalStorageService';
import { createUniversalStorageServiceFactory } from '@corefy/universal-storage/services/implementations/DefaultUniversalStorageService';
import { createVuexUniversalStoreModule } from '@corefy/universal-storage-vuex/store/modules/VuexUniversalStore';
import { countries } from '@corefy/countries/consts/countries';
import { CurrenciesMap } from '@/shared/map/CurrenciesMap';
import { CountriesMap } from '@/shared/map/CountriesMap';
import { currencies } from '@corefy/currency/consts/currencies';
import { CurrencyCode } from '@corefy/currency/enum/CurrencyCode';
import { MemberHttpClient } from '@/shared/facade/services/MemberHttpClient';
import { DefaultMemberHttpClient } from '@/shared/facade/services/implementation/DefaultMemberHttpClient';
import routes from '@/router/routes';
import { AsyncValidatorBuilderFactory } from '@corefy/validation/services/AsyncValidatorBuilder';
import { createAsyncArrayValidatorFactory } from '@corefy/validation/services/implementations/AsyncArrayValidator';
import { createAsyncCompositeValidatorFactory } from '@corefy/validation/services/implementations/AsyncCompositeValidator';
import { createAsyncValidatorBuilderFactory } from '@corefy/validation/services/implementations/AsyncValidationBuilder';
import { createAsyncFormValidationControllerFactory } from '@corefy/vue-validation/services/implementations/DefaultAsyncFormValidationController';
import { AsyncFormValidationControllerFactory } from '@corefy/vue-validation/services/AsyncFormValidationController';
import { BlogHttpClient } from '@/shared/blog/services/BlogHttpClient';
import { DefaultBlogHttpClient } from '@/shared/blog/services/implementation /DefaultBlogHttpClient';
import { createBlogStoreModule } from '@/shared/blog/store/implementation/BlogStore';
import { BlogStore } from '@/shared/blog/store/BlogStore';

export default (nuxtCtx: Context, container: Container) => {
  // ===================================== Http =====================================
  container
    .bind<HttpClientFactory>(iocTypes.HttpClientFactory)
    .toDynamicValue(() =>
      createNuxtAxiosHttpClientFactory<HttpClientConfig>({
        axios: nuxtCtx.$axios,
        defaultBeforeRequest: flatQuery,
      })
    )
    .inSingletonScope();

  // ===================================== Facade ==================================

  container.bind<SystemHttpClient>(iocTypes.SystemHttpClient).to(DefaultSystemHttpClient);

  container.bind<UserHttpClient>(iocTypes.UserHttpClient).to(DefaultUserHttpClient);

  container
    .bind<OrganizationHttpClient>(iocTypes.OrganizationHttpClient)
    .to(DefaultOrganizationHttpClient);

  container.bind<MemberHttpClient>(iocTypes.MemberHttpClient).to(DefaultMemberHttpClient);

  container.bind<BlogHttpClient>(iocTypes.BlogHttpClient).to(DefaultBlogHttpClient);

  // ===================================== Auth ==================================
  container.bind<AuthService>(iocTypes.AuthService).to(DefaultAuthService);

  container.bind<AuthRedirectService>(iocTypes.AuthRedirectService).to(DefaultAuthRedirectService);

  // ===================================== Localization =============================
  container
    .bind<LocalizationService>(iocTypes.LocalizationService)
    .toDynamicValue(() => nuxtI18nLocalisationServiceFactory<AppLocale>({ i18n: nuxtCtx.app.i18n }))
    .inSingletonScope();

  // ====================================== Services =================================
  container.bind<Router>(iocTypes.Router).toConstantValue(nuxtCtx.app.router!);

  // ===================================== Errors ===================================
  container.bind<ErrorProcessor>(iocTypes.ErrorProcessor).toDynamicValue((ctx) => {
    const reporter = ctx.container.get<ErrorReporter>(iocTypes.ErrorReporter);
    return createErrorProcessorFactory()({
      defaultHandler: new DefaultErrorsHandle(reporter),
      handlers: [new DefaultHttpErrorsHandler(reporter)],
    });
  });

  container.bind<ErrorReporter>(iocTypes.ErrorReporter).to(DefaultErrorsReporter);

  // ===================================== Indicators ================================
  container
    .bind<IndicatorsService>(iocTypes.IndicatorsService)
    .toDynamicValue((ctx) => {
      const factory = createIndicatorsServiceFactory({
        indicatorsStore: createVuexIndicatorsStoreModule({
          store: nuxtCtx.store,
          isClient: process.client,
        }),
        defaultTransformError: (e) =>
          ctx.container.get<ErrorProcessor>(iocTypes.ErrorProcessor).getInfo(e),
      });

      return factory();
    })
    .inSingletonScope();

  // ===================================== Notification =============================
  container
    .bind<NotificationServiceFactory>(iocTypes.NotificationServiceFactory)
    .toConstantValue(createNotificationServiceFactory);

  // ===================================== Validation ===============================
  container
    .bind<FormChangesServiceFactory>(iocTypes.FormChangesServiceFactory)
    .toConstantValue(createFormChangesServiceFactory());

  container
    .bind<AsyncFormValidationControllerFactory>(iocTypes.AsyncFormValidationControllerFactory)
    .toConstantValue(createAsyncFormValidationControllerFactory());

  container
    .bind<AsyncValidatorBuilderFactory>(iocTypes.AsyncValidatorBuilderFactory)
    .toDynamicValue((ctx) => {
      const l = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);

      const errorProcessor = ctx.container.get<ErrorProcessor>(iocTypes.ErrorProcessor);

      return createAsyncValidatorBuilderFactory({
        onError: (e: Error) => {
          console.error(e);
          errorProcessor.process(e);
        },

        defaultFallbackErrorMessage: () => l.t('error__async_validation_failed'),

        createCompositeValidator: createAsyncCompositeValidatorFactory(),

        createEmptyValueValidator: createEmptyValueValidatorFactory(),

        createRequiredValidator: createRequiredValidatorFactory({
          defaultMessages: {
            isRequired: () => l.t('validation__is_required'),
          },
        }),

        createArrayValidator: createAsyncArrayValidatorFactory({
          defaultMessages: {
            isNotArray: l.t('validation__array__not_array'),
            min: (min) => l.t('validation__array__min', { min }),
            max: (max) => l.t('validation__array__max', { max }),
          },
        }),

        custom: {
          stringValidator: createStringValidatorFactory({
            defaultMessages: {
              notString: () => l.t('validation__string__not_string'),
              tooShort: (min) => l.t('validation__string__too_short', { min }),
              tooLong: (max) => l.t('validation__string__too_long', { max }),
              incorrectRange: (min, max) =>
                l.t('validation__string__incorrect_range', { min, max }),
              incorrectLength: (length) => l.t('validation__string__incorrect_length', { length }),
              noSpaces: () => l.t('validation__string__no_spaces'),
            },
          }),

          regexpValidator: createRegexpValidatorFactory({
            defaultMessages: {
              regexpNotMatch: () => l.t('validation__regexp__not_match'),
            },
          }),

          phoneValidator: createPhoneValidatorFactory(ctx),

          emailValidator: createEmailValidatorFactory(ctx),
        },
      });
    });

  // ===================================== JsonApi ==================================
  container
    .bind<RelationsMapperFactory>(iocTypes.RelationsMapperFactory)
    .toConstantValue(createRelationMapperFactory());

  // ===================================== Store ====================================

  container.bind<Store<any>>(iocTypes.Store).toConstantValue(nuxtCtx.store);

  // ===================================== Organization =================================

  container
    .bind<OrganizationStore>(iocTypes.OrganizationStore)
    .toDynamicValue(createOrganizationStoreModule)
    .inSingletonScope();

  // ===================================== Navigator ===============================

  container.bind<RoutingNavigator>(iocTypes.RoutingNavigator).toDynamicValue(() => {
    const navigatorFactory = createTreeNavigatorFactory();
    return navigatorFactory(routes);
  });

  // ===================================== Env =================================

  container
    .bind<AppDataStore>(iocTypes.AppDataStore)
    .toDynamicValue(createAppDataStore)
    .inSingletonScope();

  // ===================================== User =================================

  container
    .bind<UserStore>(iocTypes.UserStore)
    .toDynamicValue(createUserStoreModule)
    .inSingletonScope();

  // ===================================== Blog =================================

  container
    .bind<BlogStore>(iocTypes.BlogStore)
    .toDynamicValue(createBlogStoreModule)
    .inSingletonScope();

  // ============================ Registration Store =================================
  container
    .bind<RegistrationStore>(iocTypes.RegistrationStore)
    .toDynamicValue(createRegistrationStore)
    .inSingletonScope();

  // ============================ ResettingPass Store =================================

  container
    .bind<ResettingPassStore>(iocTypes.ResettingPassStore)
    .toDynamicValue(createResettingPassStore)
    .inSingletonScope();

  // ============================ Portals Store =================================

  container.bind<PortalsStore>(iocTypes.PortalsStore).toDynamicValue((ctx) => {
    const universalStorageServiceFactory = ctx.container.get<UniversalStorageServiceFactory>(
      iocTypes.UniversalStorageServiceFactory
    );
    return universalStorageServiceFactory<Portals>({ namespace: 'PortalsStore' });
  });

  // ============================ Universal Store Factory Store =================================

  container
    .bind<UniversalStorageServiceFactory>(iocTypes.UniversalStorageServiceFactory)
    .toConstantValue(
      createUniversalStorageServiceFactory({
        store: createVuexUniversalStoreModule({
          store: nuxtCtx.store,
          isClient: process.client,
        }),
      })
    );

  // ====================================== Services =================================
  container
    .bind<CookieService>(iocTypes.CookieService)
    .toDynamicValue(() => nuxtCookieServiceFactory({ cookies: nuxtCtx.app.$cookies }))
    .inSingletonScope();

  // ====================================== Notifications =================================
  container.bind<ErrorAlertService>(iocTypes.ErrorAlertService).to(DefaultErrorAlertService);

  // ====================================== Map ===========================================

  container.bind<CountriesMap>(iocTypes.CountriesMap).toConstantValue(
    Array.from(countries.entries()).map(([code, data]) => ({
      code,
      name: data.name,
    }))
  );

  container
    .bind<CurrenciesMap>(iocTypes.CurrenciesMap)
    .toDynamicValue((ctx) => {
      const organizationStore = ctx.container.get<OrganizationStore>(iocTypes.OrganizationStore);

      const localization = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);

      const countriesCurrencies = Array.from(countries.entries()).map(([_code, data]) => ({
        name: data.name,
        currencies: data.currencies,
      }));

      return organizationStore.currencies.map((code) => {
        const data = currencies.get(code as CurrencyCode)!;
        return {
          code: code as CurrencyCode,
          name: data ? data.name[localization.locale] || data.name[AppLocale.English] : code,
          symbol: data ? data.symbol : code,
          countries: data
            ? countriesCurrencies
                .filter((c) => c.currencies?.includes(code as CurrencyCode))
                .map((c) => c.name)
            : [],
        };
      });
    })
    .inSingletonScope();

  return container;
};
