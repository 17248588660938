import { inject, injectable } from 'inversify';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { HttpClient, HttpClientFactory } from '@corefy/http/services/HttpClient';
import { getBaseFacadeClientConfig } from '@/shared/facade/consts/facadeClientConfig';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';
import { MemberHttpClient } from '@/shared/facade/services/MemberHttpClient';

@injectable()
export class DefaultMemberHttpClient implements MemberHttpClient {
  constructor(
    @inject(iocTypes.HttpClientFactory) createHttpClient: HttpClientFactory,
    @inject(iocTypes.AppDataStore)
    appDataStore: AppDataStore
  ) {
    const { FACADE_URL } = appDataStore.data.env;
    this._httpClient = createHttpClient(getBaseFacadeClientConfig(FACADE_URL));
  }

  acceptInvitation(id: string) {
    return this._httpClient.patch(`/member-invites/${id}/accept`);
  }

  declineInvitation(id: string) {
    return this._httpClient.patch(`/member-invites/${id}/decline`);
  }

  private _httpClient: HttpClient;
}
